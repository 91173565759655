html {
	overflow-x: hidden;
}

body {
	&.layout-boxed-lg {
		#page {
			max-width: $container-lg + 20px;
			@include box-shadow(0 0 5px lighten($gray, 50%));
			margin: 0 auto;
			background: $white;
		}
	}
	&[class*="-home"] {
		.header-1 {
			.pav-verticalmenu {
				display: none !important;
				border: 1px solid #000;
			}
			.dropdown {
				&:hover {
					.pav-verticalmenu {
						display: block;
					}
				}
			}

		}
	}
	&[class*="page-"] #content {
		padding: 20px;
	}
	&[class*="page-category"] {
		#content {
			padding: 0;
			h1 {
				margin-top: 0;
			}
		}
	}
	&[class*="-account"] #content {
		h1, h2 {
			font-size: $font-size-base + 5;
		}
	}

	&[class*="page-product"] #content {
		overflow: hidden;
		padding: 0;
		.owl-carousel .owl-wrapper-outer {
			overflow: visible;
		}
		.owl-carousel {
			margin-bottom: 35px;
		}
	}
}

.row-offcanvas {
	overflow: hidden;
}

#sys-notification {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 9999;
	right: 0;
}

.page-themecontrol-product {
	#page {
		overflow-x: hidden;
	}
}

.fancybox-type-iframe {
	.fancybox-skin {
		background: $wh;
	}
}

/*
* HEADER 
*/

#topbar {
	background: $topbar-outside-bg;
	font-size: $topbar-font-size;
	color: $topbar-color;
	height: 40px;
	line-height: 25px;
	padding: 8px 0;
	.topbar-left {
		position: relative;
	}
	.custom-top {
		&:nth-child(1) {
			margin-right: 20px;
		}
		i {
			padding-right: 5px;
		}
	}
	.container {
		.inside {
			background: $topbar-bg;
			padding: $topbar-container-padding;
		}
	}

}

.header-top {
	background-color: $header-top-bg;
	color: $white;
	font-size: $font-size-base - 2;
	font-weight: 500;
	text-transform: uppercase;
	& > .container {
		position: relative;
		.holiday_works {
			position: absolute;
			top: 114px;
			left: 525px;
		}
	}
	.inside {
		padding: 16px 0 40px;
		& > .row {
			display: flex;
		}
	}
	.btn-group {
		margin-right: 0;
	}
	.icon-down {
		background: url("#{$image-theme-path}bullet.png") no-repeat right;
		@include size(8px, 7px);
		margin-left: 5px;
		display: inline-block;
	}
	.btn-group {
		margin-right: 5px;
		button {
			background-color: transparent;
			border: none;
			padding: 0;
		}
		&:hover {
			.dropdown-menu {
				@include opacity(1);
				visibility: visible;
				display: block;
				top: 100%;
				color: $text-color;
			}
		}
		.dropdown-menu {
			min-width: 150px;
			padding: 10px;
			margin-top: 0;
			font-size: $font-size-base - 2;
			@include opacity(0);
			@include transition(all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
			top: 120%;
			position: absolute;
			visibility: hidden;
			display: block;
			> li {
				a {
					padding: 0;
					color: $text-color;
					&:hover, &:active, &:focus {
						color: $link-hover-color;
					}
				}
				&:hover {
					color: $link-hover-color;
				}
			}

		}
	}
	.quickaccess-toggle {
		cursor: pointer;
		position: relative;
		&:hover {
			.inner-toggle {
				@include opacity(1);
				visibility: visible;
				display: block;
				top: 100%;
			}
		}
		i {
			padding-left: 5px;
			&.first {
				padding-right: 5px;
				font-size: $font-size-base;
			}
		}
	}
	.inner-toggle {
		background: $white;
		min-width: 150px;
		z-index: 99999;
		left: 0;
		padding: 10px;
		@include opacity(0);
		@include transition(all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
		display: block;
		top: 120%;
		position: absolute;
		visibility: hidden;
		@include box-shadow(0 6px 12px rgba(0, 0, 0, 0.176));
		@include rounded-corners(3px);
		ul {
			padding: 0;
			li {
				display: block;
				line-height: 24px;
				width: 100%;
				a {
					color: $text-color;
					&:hover {
						color: $theme-color;
					}
				}
				span {
					padding-right: 5px;
				}
			}
		}
	}
}

.header-bottom {
	.inside {
		border: 1px solid $border-color;
		@include rounded-corners(3px);
		margin-top: -25px;
		background-color: $white;
		position: relative;
		z-index: 99;
	}
}

.search-focus {
	color: $search-icon-color;
	cursor: pointer;
	border-right: 1px solid $border-color;
	padding-right: 5px;
	line-height: 32px;
	height: 27px;
}

.nav-search {
	&.open {
		height: 44px;
		top: 0;
		visibility: visible;
		@include opacity(1);
	}
}

/* multi logo */
.logo-store {
	position: relative;
	z-index: 9999;
	text-align: left;
	a {
		vertical-align: middle;
		img {
			width: 200px;
		}
	}
}

#logo-theme {
	a {
		background: url(#{$image-theme-path}logo.png) no-repeat;
		display: inline-block;
		@include size(170px, 37px);
		span {
			display: none;
		}
	}
}

/*
* CONTENT
 */
#pav-masshead {
	.inner {
		@include container-layout-variant($masshead-color, $masshead-bg, $masshead-color, lighten($masshead-color, 5%));
	}
}

.breadcrumbs {
	.container {
		background: transparent;
	}
}

/*
* NAVIGATION
*/
#pav-mainnav {
	.inner {
		background-color: $theme-color-secondary;
	}
}

/*
* SLIDESHOW 
*/
#pavo-slideshow {
}

/*
* SHOWCASE 
*/
#pavo-showcase {
	.inner {
		background-color: $showcase-bg;
	}
}

/*
* PROMOTION TOP 
*/
#pav-promotion {
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

/*
* MAIN CONTENT
*/

/*end*/
/* FOOTER */
#footer {
	ul, ol {
		margin: 0;
		li {
			line-height: 20px;
			a {
				font-size: $font-size-base - 2;
				color: $footer-color;
				text-transform: uppercase;
				font-weight: 500;
				@include transition(all 0.4s ease 0s);
				&:hover {
					color: $white;
				}
			}
		}
	}
	.store_name {
		color: $wh;
		margin-top: 10px;
		text-align: center;
		white-space: nowrap;
	}
}

.footer-top {
	background: transparent;
	margin-bottom: -97px;
}

.footer-center {
	background: #242424;
	padding: 50px 0;
	.panel {
		background: transparent;
		margin-bottom: 0;
		.panel-heading {
			padding: 0;
		}
		.panel-body {
			padding: 20px 0 0;
		}
		.panel-title {
			color: $white;
		}
	}
}

.footer-bottom {
	background: $blue;
	.inside {
		background: url("#{$image-theme-path}pattern.jpg") repeat 0 0;
		position: relative;
		@include rounded-corners(3px);
		.bg-blue {
			background: url("#{$image-theme-path}pattern2.jpg") repeat 0 0;
		}
	}
}

/* POWER BY */
#powered {
	background: $bk;

	.container {
		position: relative;
	}
	a {
		font-size: $font-size-base - 2;
		color: $footer-color;
		font-weight: 500;
		text-transform: uppercase;
		&:hover {
			color: $white;
		}
	}
	.copyright {
		color: $white;
		font-size: $font-size-base - 2;
		font-weight: 500;
		text-transform: uppercase;
		padding: 17px 0;
		line-height: 20px;
		p {
			margin: 0;
		}
	}
	ul.links {
		line-height: 16px;
		padding-left: 20px;
		li {
			padding: 0 10px;
		}
	}
	.oferta {
		color: $wh;
		font-size: 12px;
		margin-bottom: 2px;
		text-transform: none;
	}
	.payment-img {
		display: block;
		text-align: right;

		img {
			max-height: 61px;

			&:last-child {
				display: none;
				margin: 8px auto;
			}
		}
	}
}

.for-send-error-press {
	font-style: italic;
	margin: 15px 0;
}