body {
	color: $bk;
}

a {
	color: $bk;
}

#page {
	@media (min-width: 992px) {
		min-height: calc(100vh - 298px);
	}
}

#content h1 {
	margin-top: 0;
}

.responsive-mob-menu {
	height: 100vh;
	overflow-y: hidden;
}
.main-title {
	position: absolute;
	left: -9999rem;
	top: -9999rem;
}
.main-column .container {
	background: $wh;
}

.box-col {
	em {
		font-size: 28px;
	}
	.media-body {
		font-size: 14px;
		text-transform: none;
	}
	&, .media-body {
		text-align: center;
		display: block;
	}
}

.pav-inner.container {
	padding-top: 15px;
}

.flogo {
	text-align: center;
}

.footer-contacts {
	padding: 0;
	li {
		list-style: none;
		color: #ccd6dd;
	}
}

.autosearch {
	padding: 10px 20px;
	a.media-left {
		padding: 0;
	}
	a img {
		margin-right: 10px;
	}
}

.page-information #content {
	p img, h3 img {
		max-width: 100%;
		height: auto !important;
	}
}

.product-special {
	.main-columns {
		min-height: calc(100vh - 567px);
	}
}

.shopping-cart-table {
	margin-top: 20px;
	background: $wh;
	.product-label.sale-exist {
		text-align: center;
		width: 50px;
		position: absolute;
		top: 5px;
		background-color: $tcg;
		left: 5px;
		font-size: 12px;
		border-radius: 6px;
		padding: 0 3px;
		span.product-label-special {
			display: inline-block;
		}
	}
	td.text-center.column-image {
		position: relative;
		.img-thumbnail {
			border: none;
		}
	}
	& > tbody > tr > td {
		vertical-align: middle !important;
	}
	&__product-image img {
		max-width: 80px;
	}
	&__product-price {
		font-size: 14px;
	}
	&[class^="icon-"], [class*=" icon-"] {
		font-size: 18px;
		line-height: 38px;
		display: inline-block;
		vertical-align: top;
	}
	td.image-col {
		width: 105px;
		padding-left: 0;
		padding-right: 0;
	}
	h6 {
		padding: 2px 0 5px;
	}
}

.buy {
	margin-bottom: 15px;
}

.product-grid .product-block .top {
	background: rgba(255, 255, 255, .7);
	padding-top: 10px;
}

#blackfriday{
	position: fixed;
	bottom: 0;
	width: 100%;
	background: $tcg;
	z-index: 9999;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 25px;
	line-height: 50px;
	span.text{
		padding: 0 50px;
		display: inline-block;
	}
	.fa{
		position: absolute;
		right: 15px;
		top: 50%;
		margin-top: -12px;
		font-size: 25px;
		cursor: pointer;
	}
}

table.table-total {
	font-size: 18px;
	margin-bottom: 20px;
	margin-top: 15px;
	tr > th {
		border-top: 0;
		font-weight: normal;
		&, &tr > td {
			padding: 4px 25px 4px;
			width: 50%;
		}
	}
	h2 {
		font-size: 24px;
		padding-bottom: 0;
	}
	.aside-column & {
		margin-bottom: 0;
	}
}

.th-title {
	display: none;
}

.success {
	display: none;
	width: 600px;
	margin-left: -300px;
	background: white;
	border: 1px solid #E4E3E3;
	padding: 50px;
	left: 50%;
	top: 22%;
	font-size: 16px;
	color: #333333 !important;
	-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	z-index: 15000;
	position: fixed;
	a {
		text-decoration: none !important;
		font-size: 16px;
		&:hover {
			color: $tcg;
			text-decoration: underline;
		}
	}
	.close {
		float: right;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
		opacity: 1;
	}
	.success_button {
		background: $tcg;
		border: 1px solid #E4E3E3;
		display: inline-block;
		cursor: pointer;
		color: white !important;
		font-size: 15px;
		font-weight: bold;
		padding: 6px 24px;
		text-decoration: none;
		@include transition(all 200ms ease);
		&:hover {
			text-decoration: none !important;
			background: $tch;
		}
		&:active {
			position: relative;
			top: 1px;
		}
	}
}

.product-compare-header {
	position: fixed;
	top: 250px;
	right: 0;
	width: 85px;
	background: white;
	border: 2px solid #c5c5c5;
	border-right: none;
	border-radius: 5px 0 0 5px;
	padding: 5px;
	z-index: 999;
	text-align: center;
	p {
		margin: 0;
		&#compare-total {
			font-weight: bold;
			line-height: 12px;
			font-size: 11px;
			margin-top: 2px;
		}
	}
	i {
		font-size: 28px;
	}
	.table-bordered > tbody > tr > td {
		width: 100px;
		&:first-child {
			text-align: left;
		}
	}
}

.table > tbody > tr > td {
	color: #444;
}

.btn-kn-red {
	background-color: $tcg;
	color: white;
	@include transition(all 200ms ease);
	&:hover, &:focus {
		background-color: $tch;
		color: $white;
	}
}

.btn-kn-red.button-credit {
	background-color: #c1c1c1;
	padding: 0 8px !important;
}

.btn-kn-blue {
	background-color: $bk;
	@include transition(all 200ms ease);
	color: $white;
	&:hover, &:focus {
		background-color: #333;
		color: $white;
	}
}

#product {
	.compare, .cart, .credit {
		display: inline-block;
	}
	.compare {
		.btn {
			font-family: 'FontAwesome';
		}
	}
}

.header-v1 .media-body a {
	color: $wh !important;
}

.canvas-menu {
	padding: 0 8px;
	span.fa {
		font-size: 2em;
		line-height: 35px;
	}
}

.autosearch {
	right: 0;
	left: auto;
	.media-body a {
		color: #444 !important;
	}
}

.panel-contact-custom {
	font-size: 18px;
	.fa {
		padding-right: 10px;
	}
}

#input-enquiry {
	resize: none;
}

.refine-search {
	h4 {
		font-size: 20px;
		color: $bk;
		text-transform: none;
		margin-top: 0;
	}
	.catgrid {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: 0 -10px;
		.category-home {
			width: calc((100% / 5) - 20px);
			margin: 10px;
			background: $wh;
			border: 3px solid $wh;
			a {
				img {

				}
				.name {
					text-align: center;
					padding: 15px;
					font-weight: bold;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
			&:hover {
				border-color: $tcg;
				a {
					.name {
						background: $tcg;
						color: $wh;
					}
				}
			}
		}
	}
}

#button-coupon {
	@include rounded-corners(0);
	height: 34px;
}

.widget-raw-html .box-heading {
	font-size: 27px;
	text-transform: none;
	font-weight: 600;
}

.widget-heading .panel-title {
	font-size: 21px;
	text-transform: uppercase;
	color: #333;
}

.list-unstyled li {
	position: relative;
}

.owl-wrapper {
	background: $wh;
	.owl-item {
		padding: 10px 0;
	}
}

.owl-carousel {
	.owl-nav {
		position: absolute;
		top: calc(50% - 40px);
		left: 0;
		right: 0;
		.owl-prev, .owl-next {
			background: $tcg;
			display: inline-block;
			position: absolute;
			width: 20px;
			height: 40px;
			&:hover, &:focus {
				background: $tch;
			}
			i {
				color: $wh;
				padding: 13px 5px;
			}
		}
		.owl-prev {
			left: 0;
		}
		.owl-next {
			right: 0;
		}
	}
}

.products-block {
	.owl-stage-outer {
		width: calc(100% - 1px);
	}
	.owl-stage {
		.owl-item {
			outline: 1px $lg solid;
			.cart {
				display: flex;
				justify-content: space-between;
				padding: 15px 0;
			}
		}
	}
}

.products-row {
	background: $wh;
}

.panel-title.text-related {
	font-size: 22px;
}

.attr {
	section {
		h3 {
			font-size: 18px;
			color: $wh;
			background: $dg;
			padding: 10px;
			margin: 15px 0 0 0;
			position: relative;

			&:after {
				position: absolute;
				content: '';
			}
		}
	}
	.product-set-container {
		h3 {
			position: relative;
			.title-percent {
				position: absolute;
				font-weight: bold;
				color: #F7F9FC;
				&:first-child {
					top: 1px;
					right: 124px;
					font-size: 24px;
					line-height: 29px;
					transform: rotate(0.3deg);
				}
				&:nth-child(2) {
					top: 3px;
					right: 70px;
					font-size: 48px;
					line-height: 57px;
					transform: rotate(17.85deg);
				}
				&:nth-child(3) {
					top: 25px;
					right: 58px;
					font-size: 14px;
					line-height: 17px;
					transform: rotate(-24.86deg);
				}
				&:last-child {
					top: -28px;
					right: 7px;
					font-size: 72px;
					line-height: 86px;
					transform: rotate(-24.86deg);
				}
			}
		}

		.product-set {
			padding-top: 20px;
			margin: 0;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-wrap: nowrap;
			flex-wrap: nowrap;
			.set-item {
				position: relative;
				.image {
					img {
						max-width: 100%;
					}
				}
				.name {
					font-size: 16px;
					padding-top: 10px;
				}
				.price {
					color: #000;
					font-size: 20px;
					padding: 10px 0;
				}
				.set-sep {
					display: block;
					position: absolute;
					top: 35%;
					right: -12px;
					font-size: 36px;
					font-weight: bold;
					line-height: 43px;
				}

			}
			.btn-group {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-ms-flex-direction: column;
				flex-direction: column;
				-webkit-box-pack: center;
				-ms-flex-pack: center;
				justify-content: center;
				.btn {
					line-height: 21px;
					height: unset;
					text-transform: unset;
					padding: 19px 37px;
					font-size: 18px;
				}
				.prices {
					padding-left: 30px;
					span {
						display: block;
					}
					.old-price {
						font-size: 18px;
						line-height: 22px;
						position: relative;
						padding-bottom: 6px;
						width: max-content;
						&:after {
							content: "";
							border-bottom: 1px solid red;
							position: absolute;
							top: 0;
							left: -5px;
							width: 110%;
							height: 50%;
							z-index: 1;
							transform: rotate(-10deg);
						}
					}
					.new-price {
						font-size: 36px;
						line-height: 43px;
						font-weight: bold;
						padding-bottom: 40px;
					}
				}
			}
		}
	}
}

.tcarousel {
	margin-bottom: 0;
}

.producttabs .panel-body {
	padding: 0;
	border: none;
	& > .tab-pane {
		display: block;
		height: 0;
	}
	& > .active {
		height: auto;
	}
}

.page-themecontrol-product {
	#page {
		min-height: 1px !important;
	}
}

#logo a {
	.logo-text {
		color: $wh;
		text-transform: none;
		font-size: 14px;
		position: absolute;
		left: 63px;
		bottom: 0;
	}
	&:hover .logo-text {
		color: $wh;
	}
}

.panel-v4 .img-responsive {
	border: 1px solid #ccc;
}

.footer-contacts li {
	margin-left: 23px;
}

.footer-contacts .fa {
	padding-right: 3px;
	margin-left: -23px;
}
.footer-li-flex {
	display: flex;
	align-items: center;
	margin-top: 10px;
}
.footer-flex {
	display: flex;
	flex-wrap: wrap;
	max-width: 200px;
	margin-left: 10px;
}

td.cart-thumb .img-thumbnail {
	border: none;
}

.under_the_order .fa {
	margin-right: 3px;
}

.under_the_order .text_under_the_order, .under_the_order .fa, .under_the_order a, .under_the_order .under_the_order_phone {
	font-size: 20px;
}

.advantages {
	list-style: none;
	padding-left: 0;
	font-size: 15px;
	color: #222;
	li a {
		color: #222 !important;
		&:hover {
			color: $tcg !important;
		}
	}
	.fa {
		font-size: 19px;
		width: 20px;
		text-align: right;
	}
}

.compare button {
	min-width: 48px;
}

.item.desc {
	img {
		max-width: 100%;
		height: auto;
	}
	table {
		width: 100%;
		tr {
			&:nth-child(2n+1) {
				background: #eee;
			}
			td {
				&:last-child {
					padding-left: 20px;
				}
			}
		}
		img {
			padding: 5px;
		}
	}
}

.btn.price {
	height: 40px;
	line-height: 40px;
	.fa {
		font-size: 22px;
		margin-right: 8px;
		display: inline-block;
		vertical-align: -3px;
	}
}

#showall {
	margin-top: 10px;
}

table.compare-table {
	min-width: 768px;
	background: $wh !important;
	.img-thumbnail {
		border: none;
	}
	tr {
		td {
			width: 500px;
			&:first-child {
				width: 250px;
			}
		}
	}
}

.modal-dialog .modal-header h4 {
	color: #000000;
}

#request-on-order-form {
	.btn-buy-product {
		padding: 0 37px !important;
		color: $white !important;
		@include transition(0.2s all);
	}
	label[for="input-captcha"] {
		width: 100%;
	}
	#request_on_order_input-requizit {
		height: 150px;
	}
}

#finded-low-cost-form {
	background: rgba(0, 0, 0, 0.4);
	.form-group {
		margin-bottom: 0;
		input {
			margin-bottom: 10px;
		}
	}
	& > div {
		padding-bottom: 22px;
	}
	.error {
		color: $tcg;
		margin: 0;
		position: absolute;
		bottom: 0;
	}
}

.product-info p.finded-low-cost, .link-underline {
	margin-bottom: 0;
	display: block;
	b {
		text-transform: none;
		font-size: 16px;
		color: $tcg;
		cursor: pointer;
		position: relative;
		&:after {
			content: '';
			height: 1px;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			border-bottom: 1px dotted $tcg;
		}
		&:hover {
			color: $tcg;
		}
	}
}

.custom-pull-right {
	float: right !important;
}

.wrap-contact-div {
	margin-top: 65px;
}

.contact-blocks {
	position: relative;
	margin: 0;
	font-size: 15px;
	.contact-icon {
		display: inline-block;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: $tcg;
		top: 0;
		position: absolute;
		i.fa {
			color: #FFFFFF;
			font-size: 30px;
			padding: 11px 10px 10px 10px;
			width: 50px;
			height: 50px;
			text-align: center;
		}
	}
	.contact-text {
		display: inline-block;
		padding-left: 60px;
		min-height: 60px;
		margin-bottom: 10px;
		line-height: 24px;
		&.phone {
			font-size: 17px;
			border-right: none;
			float: none;
			margin-right: initial;
		}
		a {
			font-size: 15px;
			color: $tcg;
		}
		div.contact-text-b-days {
			div {
				display: inline-block;
				&:first-child {
					width: 170px;
				}
			}
		}
	}
	.mobile-show {
		display: none;
	}
}

.map_and_enter {
	height: 400px;
	width: 100%;
}

#payment_methods {
	display: block;
	padding-top: 15px;
	width: 60%;
}

.top-contact .media-title a, .top-contact .media-title a:hover {
	color: $wh;
}

.holiday_works {
	margin: 0 !important;
}

.selectric-wrapper {
	position: relative;
	cursor: pointer;
}

.selectric-responsive {
	width: 100%;
}

.selectric {
	border: 1px solid $lg;
	border-radius: 0;
	background: $white;
	position: relative;
	overflow: hidden;
	.label {
		display: block;
		white-space: nowrap;
		overflow: visible;
		width: calc(100% - 35px);
		text-overflow: ellipsis;
		margin: 0 25px 0 10px;
		padding: 8px 0;
		font-size: 14px;
		color: $black;
		height: 30px;
		font-weight: 400;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		line-height: 1;
	}
	.button {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 38px;
		line-height: 38px;
		background-color: $white;
		color: $black;
		text-align: center;
		&:after {
			content: '';
			position: absolute;
			@include size(6px, 6px);
			border-bottom: 1px solid $black;
			border-right: 1px solid $black;
			@include transform(rotate(45deg));
			right: 10px;
			top: 10px;
		}
	}
}

.selectric-focus .selectric {
	border-color: #aaaaaa;
}

.selectric-hover .selectric {
	.button {
		color: #a2a2a2;
		&:after {
			border-top-color: #a2a2a2;
		}
	}
}

.selectric-open {
	z-index: 9999;
	.selectric .button::after {
		@include transform(rotate(225deg));
		transform-origin: 4px 5px;
	}
	.selectric-items {
		display: block;
	}
}

.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
	}
	&.selectric-is-native {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 10;
		select {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			border: none;
			z-index: 1;
			box-sizing: border-box;
			opacity: 0;
		}
	}
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.selectric-items {
	display: none;
	position: absolute;
	top: 31px;
	left: 0;
	background: $white;
	border: 1px solid $lg;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
	.selectric-scroll {
		height: 100%;
		overflow: auto;
	}
	ul, .selectric-items li {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 14px;
		line-height: 20px;
		min-height: 20px;
	}
	li {
		display: block;
		padding: 10px;
		color: $black;
		cursor: pointer;
		line-height: 16px;
		white-space: nowrap;
		&.selected, &.highlighted, &:hover {
			color: #fff;
			background: $tcg;
		}
	}
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		color: #666 !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.selectric-group {
		.selectric-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: none;
			color: #444;
		}
		&.disabled li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}

.column-quantity .btn {
	@include rounded-corners(0 !important);
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}

#checkout-form #shipping_methods .radio, #checkout-form #payment_methods .radio {
	margin-left: 0 !important;
}

.radio_custom, .checkbox_custom {
	margin-left: 0 !important;
	padding-left: 0 !important;
}

.radio_custom {
	margin-left: 10px;
	padding-left: 10px;
	label {
		width: 500px;
		display: inline-block;
		font-weight: normal;
		cursor: pointer;
	}
	.list_of_points {
		display: block;
		text-align: left;
		font-size: 14px;
		font-weight: 500;
		padding-left: 22px;
	}
	a {
		padding-left: 10px;
		font-size: 18px;
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 22px;
		position: relative;
		font-size: 14px;
		font-weight: 500;
		color: $bk;
		margin: 1px 0;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&.label {
			width: 500px;
		}
		&__indicator {
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 4px;
			left: 0;
			user-select: none;
			@include size(15px, 15px);
			text-align: center;
			background-color: $tcg;
			border: 1px solid $tcg;
		}
		&.radio input ~ .input-control__indicator:after {
			border-radius: 50%;
			content: "";
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transform: scale(0.95);
			transform-origin: center center;
			width: 100%;
			background: $tcg;
			@include opacity(0);
			@include transition(.1s opacity linear);
			border: 3px $white solid;
		}
		&.radio input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
	@media (max-width: 767px) {
		.input-control, .list_of_points {
			font-size: 14px;
		}
	}
}

.checkbox_custom {
	a {
		padding-left: 10px;
		font-size: 14px;
		color: $tcg;
		&.agree b {
			font-weight: 500;
			padding: 0;
			font-size: 12px;
		}
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 25px;
		line-height: 16px;
		position: relative;
		font-size: 12px;
		font-weight: 500;
		color: $bk;
		margin: 5px 10px 5px 14px;
		text-align: left;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			user-select: none;
			@include size(16px, 16px);
			text-align: center;
			background-color: $white;
			border: 2px $tcg solid;
			@include rounded-corners(0px);
		}
		&.checkbox input ~ .input-control__indicator:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 4px;
			transform-origin: center center;
			@include opacity(0);
			@include transition(.1s opacity linear);
			@include transform(translateZ(1px) rotate(45deg));
			border-bottom: $tcg 2px solid;
			width: 5px;
			height: 10px;
			border-right: $tcg 2px solid;
		}
		&.checkbox input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
}

#request_on_order_input-requizit {
	height: 100px;
}

.horital .owl-carousel .owl-nav {
	top: calc(50% - 20px);
	.owl-prev {
		left: 1px;
	}
}

.ytv-canvas {
	background: $bk !important;
	height: auto;
}

.abtphotos {
	display: flex;
	span {
		padding: 0 3px;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
}

body[class*="product-"] .notfound {
	padding: 20px !important;
}

.information-opt {
	ul.panel-contact-custom {
		padding: 0;
		list-style: none;
		li {
			display: flex;
			margin-bottom: 11px;
			i {
				line-height: 30px;
			}
		}
	}
}

#is_ip .radio_custom label {
	margin-right: 15px;
	width: auto;
}