// Core variables and mixins

/*Font size
---------------------------------------*/
.font-size-7 {
	font-size: 7px;
}

.font-size-10 {
	font-size: 10px;
}

.font-size-12 {
	font-size: 12px;
}

.font-size-14 {
	font-size: 14px;
}

.font-size-16 {
	font-size: 16px;
}

.font-size-18 {
	font-size: 18px;
}

.font-size-20 {
	font-size: 20px;
}

.font-size-22 {
	font-size: 22px;
}

.font-size-24 {
	font-size: 24px;
}

.font-size-26 {
	font-size: 26px;
}

.font-size-28 {
	font-size: 28px;
}

.font-size-30 {
	font-size: 30px;
}

/*Center Block "Class"*/
.center-block {
	margin: 0 auto;
	text-align: center;
}

/*Divider*/
hr.divider-30 {
	margin: 30px 0;
}

hr.divider-40 {
	margin: 40px 0;
}

hr.divider-50 {
	margin: 50px 0;
}

hr.divider-60 {
	margin: 60px 0;
}

hr.divider-70 {
	margin: 70px 0;
}

hr.divider-snow {
	margin: 50px 0;
	border-color: #ddd;
}

/*Font Weight*/
.fweight-200 {
	font-weight: 200 !important;
}

.fweight-300 {
	font-weight: 300 !important;
}

.fweight-400 {
	font-weight: 400 !important;
}

.fweight-500 {
	font-weight: 500 !important;
}

.fweight-600 {
	font-weight: 600 !important;
}

.fweight-700 {
	font-weight: 700 !important;
}

/*Letter spacing */
.letter-spacing-0 {
	letter-spacing: 0;
}

.letter-spacing-1 {
	letter-spacing: 1px;
}

.letter-spacing-2 {
	letter-spacing: 2px;
}

/*Space Padding
------------------------------------*/
/*Padding Around the Block*/
.space-padding-0 {
	padding: 0 !important;
}

.space-padding-10 {
	padding: 10px !important;
}

.space-padding-20 {
	padding: 20px !important;
}

.space-padding-30 {
	padding: 30px !important;
}

.space-padding-40 {
	padding: 40px !important;
}

.space-padding-50 {
	padding: 50px !important;
}

.space-padding-60 {
	padding: 60px !important;
}

.space-padding-70 {
	padding: 70px !important;
}

.space-padding-80 {
	padding: 80px !important;
}

.space-padding-90 {
	padding: 90px !important;
}

.space-padding-100 {
	padding: 100px !important;
}

/*Padding Top & Bottom*/
.space-padding-tb-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.space-padding-tb-10 {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.space-padding-tb-20 {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}

.space-padding-tb-30 {
	padding-top: 30px !important;
	padding-bottom: 30px !important;
}

.space-padding-tb-40 {
	padding-top: 40px !important;
	padding-bottom: 40px !important;
}

.space-padding-tb-50 {
	padding-top: 50px !important;
	padding-bottom: 50px !important;
}

.space-padding-tb-60 {
	padding-top: 60px !important;
	padding-bottom: 60px !important;
}

.space-padding-tb-70 {
	padding-top: 70px !important;
	padding-bottom: 70px !important;
}

.space-padding-tb-80 {
	padding-top: 80px !important;
	padding-bottom: 80px !important;
}

.space-padding-tb-100 {
	padding-top: 100px !important;
	padding-bottom: 100px !important;
}

/*Padding Left & Right*/
.space-padding-lr-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.space-padding-lr-10 {
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.space-padding-lr-20 {
	padding-left: 20px !important;
	padding-right: 20px !important;
}

.space-padding-lr-30 {
	padding-left: 30px !important;
	padding-right: 30px !important;
}

.space-padding-lr-40 {
	padding-left: 40px !important;
	padding-right: 40px !important;
}

.space-padding-lr-50 {
	padding-left: 50px !important;
	padding-right: 50px !important;
}

.space-padding-lr-60 {
	padding-left: 60px !important;
	padding-right: 60px !important;
}

.space-padding-lr-70 {
	padding-left: 70px !important;
	padding-right: 70px !important;
}

.space-padding-lr-80 {
	padding-left: 80px !important;
	padding-right: 80px !important;
}

.space-padding-lr-90 {
	padding-left: 90px !important;
	padding-right: 90px !important;
}

.space-padding-lr-100 {
	padding-left: 100px !important;
	padding-right: 100px !important;
}

/*Padding Top*/
.space-padding-t5 {
	padding-top: 5px !important;
}

.space-padding-t10 {
	padding-top: 10px !important;
}

.space-padding-t15 {
	padding-top: 15px !important;
}

.space-padding-t20 {
	padding-top: 20px !important;
}

.space-padding-t25 {
	padding-top: 25px !important;
}

.space-padding-t30 {
	padding-top: 30px !important;
}

/*Padding Bottom*/
.space-padding-b5 {
	padding-bottom: 5px !important;
}

.space-padding-b10 {
	padding-bottom: 10px !important;
}

.space-padding-b15 {
	padding-bottom: 15px !important;
}

.space-padding-b20 {
	padding-bottom: 20px !important;
}

.space-padding-b25 {
	padding-bottom: 25px !important;
}

.space-padding-b30 {
	padding-bottom: 30px !important;
}

/*Padding Left*/
.space-padding-l5 {
	padding-left: 5px !important;
}

.space-padding-l10 {
	padding-left: 10px !important;
}

.space-padding-l15 {
	padding-left: 15px !important;
}

.space-padding-l20 {
	padding-left: 20px !important;
}

.space-padding-l25 {
	padding-left: 25px !important;
}

.space-padding-l30 {
	padding-left: 30px !important;
}

/*Padding right*/
.space-padding-r5 {
	padding-right: 5px !important;
}

.space-padding-r10 {
	padding-right: 10px !important;
}

.space-padding-r15 {
	padding-right: 15px !important;
}

.space-padding-r20 {
	padding-right: 20px !important;
}

.space-padding-r25 {
	padding-right: 25px !important;
}

.space-padding-r30 {
	padding-right: 30px !important;
}

/*Margin Top*/
.space-top-5 {
	margin-top: 5px !important;
}

.space-top-10 {
	margin-top: 10px !important;
}

.space-top-15 {
	margin-top: 15px !important;
}

.space-top-20 {
	margin-top: 20px !important;
}

.space-top-25 {
	margin-top: 25px !important;
}

.space-top-30 {
	margin-top: 30px !important;
}

.space-top-35 {
	margin-top: 35px !important;
}

.space-top-40 {
	margin-top: 40px !important;
}

.space-top-45 {
	margin-top: 45px !important;
}

.space-top-50 {
	margin-top: 50px !important;
}

/*Margin Bottom*/
.space-5 {
	margin-bottom: 5px !important;
}

.space-10 {
	margin-bottom: 10px !important;
}

.space-15 {
	margin-bottom: 15px !important;
}

.space-20 {
	margin-bottom: 20px !important;
}

.space-25 {
	margin-bottom: 25px !important;
}

.space-30 {
	margin-bottom: 30px !important;
}

.space-35 {
	margin-bottom: 35px !important;
}

.space-40 {
	margin-bottom: 40px !important;
}

.space-45 {
	margin-bottom: 45px !important;
}

.space-50 {
	margin-bottom: 50px !important;
}

.space-55 {
	margin-bottom: 55px !important;
}

.space-60 {
	margin-bottom: 60px !important;
}

.space-65 {
	margin-bottom: 65px !important;
}

.space-70 {
	margin-bottom: 70px !important;
}

.space-75 {
	margin-bottom: 75px !important;
}

.space-80 {
	margin-bottom: 80px !important;
}

.space-85 {
	margin-bottom: 85px !important;
}

.space-90 {
	margin-bottom: 90px !important;
}

.space-95 {
	margin-bottom: 95px !important;
}

.space-100 {
	margin-bottom: 100px !important;
}

/*Margin Left*/
.space-left-5 {
	margin-left: 5px !important;
}

.space-left-10 {
	margin-left: 10px !important;
}

.space-left-15 {
	margin-left: 15px !important;
}

.space-left-20 {
	margin-left: 20px !important;
}

.space-left-25 {
	margin-left: 25px !important;
}

.space-left-30 {
	margin-left: 30px !important;
}

/*Margin right*/
.space-right-5 {
	margin-right: 5px !important;
}

.space-right-10 {
	margin-right: 10px !important;
}

.space-right-15 {
	margin-right: 15px !important;
}

.space-right-20 {
	margin-right: 20px !important;
}

.space-right-25 {
	margin-right: 25px !important;
}

.space-right-30 {
	margin-right: 30px;
}

.space-margin-tb-60 {
	margin-top: 60px !important;
	margin-bottom: 60px !important;
}

/*Removes space*/
.space-top-0 {
	margin-top: 0 !important;
	padding-top: 0 !important;
}

.space-bottom-0 {
	margin-bottom: 0 !important;
	padding-bottom: 0 !important;
}

.no-space-row {
	.row {
		margin: 0;
	}
}

.no-space-row [class^="col-"],
.no-space-row [class*="col-"] {
	padding: 0;
}

/*Space for Ul li List
------------------------------------*/
.ul-space-5 li {
	margin-bottom: 5px !important;
}

.ul-space-10 li {
	margin-bottom: 10px !important;
}

.ul-space-15 li {
	margin-bottom: 15px !important;
}

.ul-space-20 li {
	margin-bottom: 20px !important;
}

.ul-space-25 li {
	margin-bottom: 25px !important;
}

.ul-space-30 li {
	margin-bottom: 30px !important;
}

.ul-space-35 li {
	margin-bottom: 35px !important;
}

.ul-space-40 li {
	margin-bottom: 40px !important;
}

.ul-space-45 li {
	margin-bottom: 45px !important;
}

.ul-space-50 li {
	margin-bottom: 50px !important;
}

/*Height
------------------------------------*/
.height-10 {
	height: 10px;
}

.height-20 {
	height: 20px;
}

.height-30 {
	height: 30px;
}

.height-40 {
	height: 40px;
}

.height-50 {
	height: 50px;
}

.height-60 {
	height: 60px;
}

.height-70 {
	height: 70px;
}

.height-80 {
	height: 80px;
}

.height-90 {
	height: 90px;
}

.height-100 {
	height: 100px;
}

/*Width
------------------------------------*/
.width-10 {
	width: 10%;
	margin: 0 auto;
}

.width-20 {
	width: 20%;
	margin: 0 auto;
}

.width-30 {
	width: 30%;
	margin: 0 auto;
}

.width-40 {
	width: 40%;
	margin: 0 auto;
}

.width-50 {
	width: 50%;
	margin: 0 auto;
}

/*Radius
------------------------------------*/
.radius-0 {
	border-radius: 0 !important;
}

.radius-x {
	border-radius: 50% !important;
}

.radius-1x {
	border-radius: 10px !important;
}

.radius-2x {
	border-radius: 20px !important;
}

.radius-3x {
	border-radius: 30px !important;
}

.radius-4x {
	border-radius: 40px !important;
}

.radius-5x {
	border-radius: 5px !important;
}

.radius-6x {
	border-radius: 3px !important;
}

/* Width for Border */
.border-2 {
	border-width: 2px !important;
}

.border-3 {
	border-width: 3px !important;
}

.border-4 {
	border-width: 4px !important;
}

.border-5 {
	border-width: 5px !important;
}

.border-top {
	border-top: 1px solid $border-color;
}

.border-right {
	border-right: 1px solid $border-color;
}

.border-bottom {
	border-bottom: 1px solid $border-color;
}

.border-after {
	position: relative;
	&:after {
		background: $border-color;
		content: "";
		margin-top: -50px;
		position: absolute;
		top: 50%;
		right: 0;
		@include size(1px, 50%);
	}
}

/*Radius for Left & Right Corners*/
.radius-left-5x {
	border-top-left-radius: 50px !important;
	border-bottom-left-radius: 50px !important;
}

.radius-right-5x {
	border-top-right-radius: 50px !important;
	border-bottom-right-radius: 50px !important;
}

.radius-top-5xxs {
	border-top-left-radius: 5px !important;
	border-top-right-radius: 5px !important;
}

.radius-bottom-5xxs {
	border-bottom-left-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
}

.radius-left-5xxs {
	border-top-left-radius: 5px !important;
	border-bottom-left-radius: 5px !important;
}

.radius-right-5xxs {
	border-top-right-radius: 5px !important;
	border-bottom-right-radius: 5px !important;
}

/*Text Colors
------------------------------------*/
.text-default {
	color: $brand-default !important;
}

.text-primary {
	color: $brand-primary !important;
}

.text-info {
	color: $brand-info !important;
}

.text-danger {
	color: $brand-danger !important;
}

.text-warning {
	color: $brand-warning !important;
}

.text-success {
	color: $brand-success !important;
}

.text-white {
	color: #fff !important;
}

.text-purple {
	color: #c8d1e6 !important;
}

.text-red {
	color: #c32322 !important;
}

.text-orange {
	color: #f66000 !important;
}

.text-yellow {
	color: #e2d951 !important;
}

.text-darker {
	color: #333 !important;
}

/*Background Colors
------------------------------------*/
.bg-primary {
	background-color: $brand-primary !important;
}

.bg-info {
	background: $brand-info !important;
}

.bg-danger {
	background: $brand-danger !important;
}

.bg-warning {
	background: $brand-warning !important;
}

.bg-success {
	background: $brand-success !important;
}

.bg-white {
	background: #fff;
}

.bg-purple {
	background: #c8d1e6 !important;
}

.bg-red {
	background: #c32322 !important;
}

.bg-orange {
	background: #fca311;
}

.bg-yellow {
	background: #ffd540;
}

.bg-default {
	background: #343434;
}

.bg-darker {
	background: $gray-dark !important;
}

.bg-lighten {
	background: lighten($gray-base, 92%) !important;
}

.bg-blue-lighter {
	background: $blue !important;
}

/*Overflow
------------------------------------*/
.overflow-h {
	overflow: hidden;
}

.overflow-a {
	overflow: auto;
}

/***/

.background-img {
	background: $brand-default;
}

.background-img-v1 {
	background: url(#{$image-theme-path}icon-1.png) no-repeat left 25px;
}

.parallax {
	background-size: cover;
	background-attachment: fixed;
	background-position: center center;
	position: relative;
	background-image: $bo-parallax-before-background;
	&:after {
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: -1;
		content: " ";
		height: 100%;
		text-align: center;
		position: absolute;
		background: rgba(0, 0, 0, 0.3);
	}
}

/* Height progress */
.progress-3 {
	height: 3px;
}

.progress-4 {
	height: 4px;
}

.progress-5 {
	height: 5px;
}

.progress-6 {
	height: 6px;
}

.progress-7 {
	height: 7px;
}

.progress-8 {
	height: 8px;
}

.progress-9 {
	height: 9px;
}

.progress-10 {
	height: 10px;
}

.progress-11 {
	height: 11px;
}

.progress-12 {
	height: 12px;
}

.progress-13 {
	height: 13px;
}

.progress-14 {
	height: 14px;
}

.progress-15 {
	height: 15px;
}

/* icon */
.fa {
	&.fa-2 {
		font-size: 2em;
	}
	&.fa-3 {
		font-size: 4em;
	}
	&.fa-4 {
		font-size: 7em;
	}
	&.fa-5 {
		font-size: 12em;
	}
	&.fa-6 {
		font-size: 20em;
	}
}

/* carousel-controls */
.carousel-controls {
	.carousel-control {
		z-index: 99;
	}
}

.carousel-controls-small {
	width: 65px;
	position: relative;
	display: inline-block;
	.carousel-control-small {
		@include size(28px, 28px);
		line-height: 26px;
	}
}

/* --- SCSS For Accordion --- */
// Core variables and mixins

.accordion {
	.panel {
		@include box-shadow(0 0px 0px rgba(0, 0, 0, .05));
	}
	.panel-heading {
		padding: 15px;
		position: relative;
		a {
			text-decoration: none;
			font-weight: bold;
			&:before {
				content: "-";
				font-size: 22px;
				font-weight: 600;
				@include size(20px, 20px);
				text-align: center;
				line-height: 16px;
				display: inline-block;
				color: $bo-accordion-color;
			}
			&.collapsed:before {
				content: "+";
				font-size: 18px;
				line-height: 20px;
			}
		}
	}
	.panel-body {
		border-top-color: transparent !important;
	}

	&.collapse-right {
		.panel-heading {
			a:before {
				position: absolute;
				right: 20px;
			}
		}
	}

	&.collapse-color {
		.panel-heading a {
			&:before {
				color: #fff;
				background: $bo-accordion-background;
			}
		}
	}
	&.noborder {
		.panel-heading {
			padding-left: 0;
			padding-right: 0;
		}
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
			border: none;
		}

	}
	&.nobackground {
		.panel, .panel-heading, .panel-body {
			background-color: transparent;
		}
	}
}

/* accordion style version 1 */
.accordion-v1 {
	.panel-heading a {
		&:before {
			left: -10px;
			position: absolute;
		}
	}
	.panel, .panel-heading, .panel-body {
		border: none;
	}
}

/* accordion style version 2 */
.accordion-v2 {
	.panel, .panel-heading, .panel-body {
		border: none;
	}
}

/* accordion style version 3 */
.accordion-v3 {
	.panel .panel-heading {
		border-bottom: solid 1px $bo-accordion-border-color;
	}
}

/* accordion style version 4 */
.accordion-v4 {
	.panel {
	}
	.panel-collapse {
		position: relative;
		&.in {
			&:before {
				position: absolute;
				content: "";
				background-color: $bo-accordion-background;
				top: -51px;
				left: -1px;
				height: 3px;
				width: 100.5%
			}
		}
	}
}

/* --- SCSS For Breadcrumb --- */
// Core variables and mixins

.breadcrumbs {
	padding: 0;
	h2 {
		margin: 0;
		float: left;
		font-weight: 400;
		position: relative;
	}
	.breadcrumb-links {
		margin: 0;
		position: relative;
		padding: 0 0 10px;
		border-bottom: 1px solid $border-color-base;
		> li {
			display: inline-block;
			a {
				text-transform: uppercase;
				font-weight: 500;
				font-size: $font-size-base - 2;
			}
			+ li:before {
				color: #757575;
				content: "\f105";
				font-family: FontAwesome;
				padding: 0 15px;
				font-size: 14px;
			}
			&:last-child {
				a {
					color: $black;
				}
			}
		}
		> .active {
			color: $link-hover-color;
		}
	}
	&.light-style {
		color: #fff;
		border: 0;
		h2 {
			color: #fff;
		}
		.breadcrumb-links {
			> li {
				a {
					color: #FFF;
				}
				+ li:before {
					color: #fff;
				}
			}
			> .active span {
				color: #fff;
				text-decoration: underline;
			}
		}
	}
}

.breadcrumbs-center {
	text-align: center;
	padding: 50px;
	h2 {
		float: none;
	}
	.breadcrumb-links {
		text-align: center;
	}
}

.breadcrumbs-left {
	h2 {
		float: none;
		text-align-last: right;
	}
	.breadcrumb-links {
		text-align-last: right;
	}
}

.breadcrumbs-right {
	h2 {
		float: none;
		text-align: right;
	}
	.breadcrumb-links {
		text-align: right;
	}
}

/* --- SCSS For Buttons --- */
// Core variables and mixins

.btn-outline-light {
	@include button-variant-outline(#bbbbbb, transparent, $border-color, $brand-primary, #FFFFFF, $brand-primary);
}

.btn-inverse-light {
	@include button-variant-outline($brand-primary, #FFFFFF, $brand-primary, #FFFFFF, transparent, #FFFFFF);
}

.btn-outline {
	@include button-outline(default, #000, #FFFFFF);
	@include button-outline(primary, $brand-primary, #FFFFFF);
	@include button-outline(success, $brand-success, #FFFFFF);
	@include button-outline(info, $brand-info, #FFFFFF);
	@include button-outline(danger, $brand-danger, #FFFFFF);
	@include button-outline(warning, $brand-warning, #FFFFFF);
}

.btn-inverse {
	@include button-inverse(default, #000, #FFFFFF);
	@include button-inverse(primary, $brand-primary, #FFFFFF);
	@include button-inverse(success, $brand-success, #FFFFFF);
	@include button-inverse(info, $brand-info, #FFFFFF);
	@include button-inverse(danger, $brand-danger, #FFFFFF);
	@include button-inverse(warning, $brand-warning, #FFFFFF);
}

.btn-3d {
	@include button-3d(lg, -5px, rgba(0, 0, 0, 0.15));
	@include button-3d(empty, -5px, rgba(0, 0, 0, 0.15));
	@include button-3d(sm, -4px, rgba(0, 0, 0, 0.15));
	@include button-3d(xs, -2px, rgba(0, 0, 0, 0.15));
}

/* --- SCSS For Call-to-action --- */
// Core variables and mixins

.call-to-action {
	padding: 50px 80px;
	z-index: 1;
	text-align: center;
	width: 100%;
	&.light-style {
		color: $white;
		h1, h2, h3, h4, h5, h6 {
			color: #fff;
		}
		.btn {
			border-color: #fff;
			color: #fff;
		}
	}
	.action-button .btn {
		margin-top: 30px;
		min-width: 150px;
		margin-left: 8px;
		margin-right: 8px;
	}
}

/* call to action style1 */

.call-to-action-v1 {
	display: table;
	text-align: left;
	.call-to-action-inner {
		display: table-cell;
		vertical-align: middle;
	}
	.action-button {
		min-width: 25%;
		text-align: right;
		.btn {
			margin-top: 0;
		}
	}
}

/* call to action style2 */

.call-to-action-v2 {
	display: table;
	text-align: right;

	.call-to-action-inner {
		display: table-cell;
		vertical-align: middle;
	}
	.action-button {
		min-width: 40%;
		text-align: left;
		padding-left: 50px;
		.btn {
			margin-top: 0;
		}
	}
}

/* call to action style3 */

.call-to-action-v3 {
	.call-to-action-inner {
		h1, h2, h3, h4, h5, h6 {
			border: 1px solid #222;
			@include border-top-radius(4px);
			@include border-bottom-radius(4px);
			display: inline-block;
			padding: 15px 10%;
		}
	}
	&.light-style {
		.call-to-action-inner {
			h1, h2, h3, h4, h5, h6 {
				border: 1px solid #fff;
			}
		}
	}

}

/* call to action style4 */
.call-to-action-v4 {
	padding-left: 18%;
	padding-right: 18%;
	.fa {
		@include size(90px, 90px);
		line-height: 90px;
		background-color: $brand-primary;
		text-align: center;
		color: #fff;
	}
}

/* call to action style6 */
.call-to-action-v6 {
	outline: 1px solid lighten(#000, 60%);
	outline-offset: -30px;
	padding: 95px;
	overflow: hidden;
	.heading-title {
		text-transform: uppercase;
		color: #000;
		font-weight: 700;
		font-family: $headings-font-family;
	}
	.call-to-action-inner {
		color: #bbb;
		font-size: 24px;
		float: left;
	}
	.action-button {
		float: right;
	}
}

/* --- SCSS For Content-slider --- */

.carousel-controls {
	left: auto;
	position: absolute;
	right: 25px;
	top: 0;
	height: 30px;
	display: inline-block;
	margin-top: -48px;
	&.carousel-style {
		position: relative;
		min-height: 80px;
		margin: 0 20px;
	}
	&.carousel-center {
		text-align: center;
		.carousel-control {
			margin: 0;
			position: static;
			display: inline-block;
		}
	}
}

.carousel-control {
	z-index: 99;
}

.carousel-md {
	@include size($bo-carousel-md-width, $bo-carousel-md-height);
	line-height: $bo-carousel-md-height;
}

.carousel-sm {
	@include size($bo-carousel-sm-width, $bo-carousel-sm-height);
	line-height: $bo-carousel-sm-height;
	font-size: $font-size-base + 5;
}

.carousel-xs {
	@include size($bo-carousel-xs-width, $bo-carousel-xs-height);
	line-height: $bo-carousel-xs-height;
	font-size: $bo-carousel-sm-font-size;
}

.carousel-controls-v1 {
	position: absolute;
	right: 0;
	top: 16px;
	.carousel-control {
		box-shadow: none;
		color: $bo-carousel-controls-v1-color;
		@include transition(all 0.35s ease 0s);
		border: $brand-border;
		@include rounded-corners(3px);
		&.left {
			left: -88px;
		}
		&:hover {
			.icon-angle {
				&.left {
					background-position: 13px -17px;
				}
				&.right {
					background-position: -15px -17px;
				}
			}
		}
		.icon-angle {
			background: url("#{$image-theme-path}bullet2.png") no-repeat 13px 9px;
			height: 30px;
			@include transition(all 0.35s ease 0s);
			display: block;
			&.right {
				background-position: -15px 9px;
			}
		}
	}
}

.carousel-controls-v2 {
	.carousel-control {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) inset;
		color: $bo-carousel-controls-v2-color;
		font-size: 28px;
		@include size(28px, 28px);
		line-height: 28px;
		@include transition(all 0.35s ease 0s);
		&:hover {
			box-shadow: 0 0 0 rgba(0, 0, 0, 0) inset;
			color: $bo-carousel-controls-v2-hover-color;
		}
	}
}

.carousel-controls-v3 {
	.carousel-control {
		box-shadow: none;
		@include transition(all 0.35s ease 0s);
		color: $theme-color-tertiary;
		&:hover {
			color: $bo-carousel-controls-v3-hover-color;
			&.left {
				color: $black
			}
			&.right {
				color: $black;
			}
		}
		&.left {
			left: -52px;
		}
		&.right {

		}
	}
}

.carousel-controls-v4 {

	.carousel-control {
		box-shadow: none;
		color: $bo-carousel-controls-v1-color;
		@include transition(all 0.35s ease 0s);
		top: 50px;
		&.left {
			left: -88px;
		}
		&:hover {
			.icon-angle {
				&.left {
					background-position: 0 -50px;
				}
				&.right {
					background-position: -50px -50px;
				}
			}
		}
		.icon-angle {
			background: url("#{$image-theme-path}bullet4.png") no-repeat 0 -2px;
			@include size(40px, 42px);
			@include transition(all 0.35s ease 0s);
			display: block;
			&.right {
				background-position: -50px -2px;
			}
		}
	}
}

/* Navigation Styles */
[class*="carousel-indicators-"] {
	li {
		cursor: pointer;
		position: relative;
		border: none;
		a {
			cursor: pointer;
			@include size(100%, 100%);
			border-radius: 50%;
			left: 0;
			outline: medium none;
			position: absolute;
			top: 0;
			background-color: rgba(0, 0, 0, 0);
			transform-style: preserve-3d;
			@include transition (transform 0.3s ease 0s, opacity 0.3s ease 0s);
		}
		&.active {
			background-color: transparent;
		}
	}
}

.carousel-indicators-v1 {
	li {
		a {
			&:before, &:after {
				backface-visibility: hidden;
				background-color: $bo-carousel-indicators-v1-bg;
				border-radius: 50%;
				content: "";
				left: 0;
				position: absolute;
				text-indent: 0;
				top: 0;
				@include size(100%, 100%);
			}
			&:after {
				background-color: $bo-carousel-indicators-v1-hover-bg;
				@include rotateY(180deg);
			}
			&:hover, &:focus {
				opacity: 0.8;
			}
		}
		&.active {
			a {
				@include rotateY(180deg);
			}
		}
	}
}

.carousel-indicators-v2 {
	li {
		margin: 0 3px;
		a {
			@include transition (transform 0.3s ease 0s, background-color 0.3s ease 0s);
			background-color: $bo-carousel-indicators-v2-bg;
			&:hover, &:focus {
				background-color: #d5d5d5;
			}
		}
		&.active {
			margin: 0 3px;
			a {
				background-color: $bo-carousel-indicators-v2-hover-bg;
			}
		}
	}
}

.carousel-indicators-v3 {
	li {
		@include size(14px, 14px);
		a {
			background-color: rgba(0, 0, 0, 0);
			box-shadow: 0 0 0 1px $bo-carousel-indicators-v3-bg inset;
			overflow: hidden;
			@include transition (all 0.3s ease 0s);
			&:after {
				background-color: $bo-carousel-indicators-v3-bg;
				border-radius: 50%;
				content: "";
				left: 0;
				position: absolute;
				@include scale(0);
				transform-origin: 50% 50% 0;
				@include transition (all 0.3s ease 0s);
				@include size(100%, 100%);
			}
			&:hover, &:focus {
				opacity: 0.8;
			}
		}
		&.active {
			@include size(14px, 14px);
			a {
				&:after {
					@include scale(1);
				}
			}
		}
	}
}

.carousel-indicators-v4 {
	li {
		margin: 0 3px;
		@include size(14px, 14px);
		a {
			box-shadow: 0 0 0 8px $bo-carousel-indicators-v4-bg inset;
			@include transition (box-shadow 0.3s ease 0s);
			&:hover, &:focus {
				box-shadow: 0 0 0 8px $bo-carousel-indicators-v4-bg inset;
			}
		}
		&.active {
			@include size(14px, 14px);
			margin: 0 3px;
			a {
				box-shadow: 0 0 0 2px $bo-carousel-indicators-v4-bg inset;
			}
		}
	}
}

.carousel-indicators-v5 {
	li {
		margin: 0 3px;
		border-radius: 50%;
		box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
		@include transition (box-shadow 0.3s ease 0s);
		@include size(14px, 14px);
		a {
			background-color: rgba(0, 0, 0, 0.2);
			@include transition (background-color 0.3s ease 0s, transform 0.3s ease 0s);
			&:hover, &:focus {
				background-color: $bo-carousel-indicators-v5-bg;
			}
		}
		&.active {
			@include size(14px, 14px);
			margin: 0 3px;
			box-shadow: 0 0 0 2px $bo-carousel-indicators-v5-bg;
			a {
				background-color: $bo-carousel-indicators-v5-bg;
				@include scale(0.4);
			}
		}
	}
}

.carousel-indicators-v6 {
	li {
		@include size(14px, 14px);
		a {
			border: 2px solid #ddd;
			@include transition (border-color 0.3s ease 0s);
			&:after {
				background: none repeat scroll 0 0 $bo-carousel-indicators-v6-bg;
				border-radius: 50%;
				box-shadow: 0 0 1px $bo-carousel-indicators-v6-bg;
				content: "";
				@include size(100%, 100%);
				left: 0;
				@include opacity (0);
				position: absolute;
				top: 0;
				@include scale(3);
				@include transition (opacity 0.3s ease 0s, transform 0.3s ease 0s, visibility 0s ease 0.3s);
				visibility: hidden;
			}
			&:hover, &:focus {
				border-color: $bo-carousel-indicators-v6-hover-bg;
			}
		}
		&.active {
			@include size(14px, 14px);
			a {
				border-color: $bo-carousel-indicators-v6-bg;
				&:after {
					@include opacity (1);
					@include scale(1);
					@include transition (opacity 0.3s ease 0s, transform 0.3s ease 0s);
					visibility: visible;
				}
			}
		}
	}
}

/* --- SCSS For Counters --- */
// Core variables and mixins

.counters {
	text-align: center;
	.counter-wrap {
		font-size: $bo-counter-font-size;
		color: $bo-counter-color;
		font-weight: $bo-counter-font-weight;
		.fa {
			font-size: $bo-counter-icon-font-size;
		}
	}
	h5 {
		font-weight: $bo-counter-heading-font-weight;
		margin: 5px 0 30px;
		text-transform: uppercase;
	}
}

/* --- SCSS For Divider --- */
// Core variables and mixins

[class*="hr-"] {
	min-height: 1px;
}

.hr-dashed {
	border-top: 1px dashed $hr-border;
}

.hr-dotted {
	border-top: 1px dotted $hr-border;
}

.hr-double {
	border-top: 3px double $hr-border;
}

.hr-double-dashed {
	border-top: 1px dashed $hr-border;
	position: relative;
	&:before {
		border-top: 1px dashed $hr-border;
		position: absolute;
		@include size(100%, 100%);
		content: "";
		top: 1px;
	}
}

.hr-double-dotted {
	border-top: 1px dotted $hr-border;
	position: relative;
	&:before {
		border-top: 1px dotted $hr-border;
		position: absolute;
		@include size(100%, 100%);
		content: "";
		top: 1px;
	}
}

/* --- SCSS For Google-map --- */
// Core variables and mixins

/* --- SCSS For Heading --- */
// Core variables and mixins

.heading {
	z-index: 1;
	position: relative;
	text-align: center;
	h1, h2, h3, h4, h5, h6 {
		text-transform: uppercase;
	}
	p, small {
		margin-bottom: 15px;
		display: inline-block;
	}
	&.heading-light {
		color: #eee;
		h1, h2, h3, h4, h5, h6 {
			color: #fff;
		}
		p, small {
			color: #eee;
		}
	}
	&.heading-after {
		position: relative;
		&:after {
			left: 50%;
			@include size(30px, 2px);
			margin-left: -15px;
			content: " ";
			top: 0;
			background: $brand-primary;
			position: absolute;
		}
	}

	&.heading-v1 {
		position: relative;
		padding-bottom: 35px;
		&:before, &:after {
			left: 50%;
			@include size(80px, 5px);
			content: "";
			bottom: 30px;
			position: absolute;
		}
		&:before {
			margin-left: -80px;
			background: $bo-heading-color;
		}
		&:after {
			background: #444;
		}
		&.heading-light:after {
			background: #fff;
		}
	}
	&.heading-v2 {
		padding-bottom: 35px;
		&:after {
			left: 50%;
			@include size(200px, 1px);
			z-index: 1;
			content: " ";
			bottom: 30px;
			background: $bo-heading-color;
			text-align: center;
			position: absolute;
			margin-left: -100px;
		}
		&:before {
			left: 50%;
			@include size(12px, 12px);
			margin-left: -6px;
			@include rotate(45deg);
			z-index: 2;
			content: " ";
			bottom: 25px;
			text-align: center;
			position: absolute;
			background: #fff;
			border: 1px solid $bo-heading-color;
		}
		&.heading-light {
			&:after {
				background: #fff;
			}
			&:before {
				background: $bo-heading-color;;
				border: 1px solid #fff;
			}
		}
	}
	&.heading-v3 {
		padding-bottom: 35px;
		&:after {
			left: 50%;
			@include size(100px, 2px);
			margin-left: -50px;
			content: " ";
			bottom: 30px;
			background: $bo-heading-color;
			position: absolute;
		}
		span {
			display: block;
			margin-bottom: 20px;
		}
		&.heading-light {
			&:after {
				background: $bo-heading-color;
			}
		}
	}

	&.heading-v4 {
		z-index: 1;
		text-align: center;
		padding-top: 70px;
		position: relative;
		&:before {
			left: 50%;
			z-index: -1;
			@include size(50px, 50px);
			content: "";
			top: 11px;
			text-align: center;
			position: absolute;
			background-color: $bo-heading-color;
			@include rotate(45deg);
			margin-left: -25px;
		}
		&:after {
			left: 50%;
			z-index: -1;
			@include size(50px, 50px);
			line-height: 50px;
			content: "\f109";
			font-family: FontAwesome;
			top: 11px;
			text-align: center;
			position: absolute;
			color: #fff;
			margin-left: -25px;
			font-size: 20px;
		}
		&.heading-light {
			&:before {
				background: #fff;
			}
		}
	}
	&.heading-v5 {
		padding-bottom: 20px;
		//background: url("../images/dotted.png") repeat-x scroll center center ;
		h1, h2, h3, h4, h5, h6 {
			padding: 0 30px;
			position: relative;
			display: inline-block;
		}
	}
	&.heading-v6 {
		h1, h2, h3, h4, h5, h6 {
			position: relative;
			margin-bottom: 35px;
			&:after {
				left: 50%;
				@include size(80px, 2px);
				margin-left: -40px;
				content: " ";
				bottom: -18px;
				background: #444;
				position: absolute;
			}
		}
		&.heading-light {
			h1, h2, h3, h4, h5, h6 {
				&:after {
					background: #fff;
				}
			}
		}
	}
	&.heading-v7 {
		padding-bottom: 40px;
		&:before {
			border-left: 12px solid transparent;
			border-right: 12px solid transparent;
			border-top: 18px solid $bo-heading-color;
			bottom: 20px;
			content: "";
			@include size(18px, 14px);
			left: 50%;
			margin-left: -18px;
			position: absolute;
		}
		&:after {
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 12px solid #ffffff;
			bottom: 24px;
			content: "";
			left: 50%;
			margin-left: -14px;
			position: absolute;
			@include size (10px, 8px);
		}
		&.heading-light {
			&:before {
				border-top: 18px solid #fff;
			}
			&:after {
				border-top: 12px solid $bo-heading-color;
			}
		}
	}

	&.heading-v8 {
		h1, h2, h3, h4, h5, h6 {
			position: relative;
			padding: 20px 0 25px;
			&:before, &:after {
				content: ".";
				font-size: 22px;
				left: 50%;
				position: absolute;
				color: #c1c1c1;
				text-shadow: 10px 0 #ddd, -10px 0 #ddd;
			}
			&:before {
				top: -15px;
			}
			&:after {
				bottom: 0;
			}
		}
	}
	&.heading-v9 {
		text-align-last: right;
		h1, h2, h3, h4, h5, h6 {
			position: relative;
			margin-bottom: 30px;
			&:after {
				left: 0;
				@include size(30px, 2px);
				content: " ";
				top: -20px;
				right: inherit;
				background-color: $bo-heading-color;
				position: absolute;
			}
		}
		&.heading-light {
			h1, h2, h3, h4, h5, h6 {
				&:after {
					background-color: #fff;
				}
			}
		}
	}
}

/* --- SCSS For Icon-box --- */
@include keyframes(hover-imagecategory) {
	0% {
		transform: perspective(200px) rotate3d(0, 1, 0, -90deg) translateX(-100%);
	}
	100% {
		transform: perspective(200px) rotate3d(0, 1, 0, 0deg) translateX(0px);
	}
}

.feature-box {
	.fbox-icon {
		float: left;
		margin: 10px 20px 0 0;
		@include transform-origin(50% 100%);
	}
	.fbox-body {
		overflow: hidden;
		.fbox-icon {
			margin-top: 0;
		}
	}
	small {
		font-size: 13px;
		margin-bottom: 15px;
		display: inline-block;

	}
	h4 {
		text-transform: uppercase;
		font-weight: bold;
		margin: 0;
	}
	.fbox-title {
		margin: 10px 0;
		font-weight: 600;
		text-transform: none;
	}
	&.light-style {
		color: #dfdfdf;
		a {
			color: #fff;
			&:hover {
				color: #000;
			}
		}
		.fbox-title,
		h4 {
			color: #fff;
		}
	}
	&.feature-box-right {
		text-align: right;
		.fbox-icon {
			float: right;
			margin: 10px 0 0 20px;
		}
	}
	&.feature-box-center {
		text-align: center;
		padding: 0 16px;
		.fbox-icon {
			float: none;
		}
	}
	&:hover {
		.fbox-icon {
			@include animation(hover-imagecategory 0.6s $ease-out-quad 1);
		}
	}
}

[class*="feature-box-"] {
	.fbox-icon {
		float: none;
		margin: 0;
	}
	h4 {
		padding: 5px 0;
		position: relative;
	}
	&.feature-box-center {
	}
}

.feature-box-v1 {
	color: #ccd6dd;
	font-size: $font-size-base + 2;
	padding: 12px 30px;
	.fbox-icon {
		float: left;
		margin-right: 15px;
		.icon {
			@include size(45px, 30px);
			background: url("#{$image-theme-path-base}icon-1.png") no-repeat 0 0;
			display: block;
			color: #fff;
			&.icons-v2 {
				background-position: -43px 0;
			}
			&.icons-v3 {
				background-position: -81px 0;
			}
		}
	}
	.fbox-body {
		line-height: 30px;
		p {
			margin: 0;
			padding: 0;
		}
		a {
			color: #ccd6dd;
		}
		&.white {
			font-size: $font-size-base + 4;
			font-weight: 200;
			strong {
				font-size: $font-size-base + 10;
				font-weight: bold;
			}
		}
	}
}

.feature-box-v2 {
	.fbox-icon {
		float: left;
		margin-right: 15px;
		.icons {
			@include size(45px, 45px);
			color: #fff;
		}
	}
	.fbox-body {
		h4 {
			color: $theme-color-default;
			font-size: $font-size-base + 3;
			font-weight: 500;
		}
		font-weight: 300;
		p {
			margin: 0;
			padding: 0;
		}
	}
}

.feature-box-v3 {
	.fbox-icon {
		.icons {
			@include size(70px, 70px);
			line-height: 70px;
			font-size: 30px;
			&.icons-plain {
				@include size(auto, auto);
				line-height: 100%;
			}
		}
	}
	&.feature-box-right {
		.fbox-icon {
			float: none;
			margin: 0;
		}
		h4:before {
			left: inherit;
			right: 0;
		}
	}
}

.feature-box-v4 {
	position: relative;
	border: 1px solid #333;
	padding: 35px 30px 20px;
	text-align: center;
	.fbox-icon {
		position: absolute;
		content: "";
		left: 0;
		right: 0;
		top: -36px;
		margin: 0 auto;
		.icons {
			font-size: 24px;
			@include size(70px, 70px);
			line-height: 70px;
			background-color: #fff;
			border: 1px solid #333;
			color: #333;
		}
		&.fbox-bg {
			.icons {
				background-color: $brand-primary;
				border: 1px solid $brand-primary;
				color: #fff;
			}
		}
	}
	h4 {
		padding: 10px 0 20px;
		&:before {
			@include size(0px, 0px);
			background-color: transparent;
		}
	}
	&[class*="bg-"] {
		border: none;
	}

}

.feature-box-v5 {
	padding: 20px 30px;
	border: 1px solid #ddd;
	border-bottom: 3px solid $brand-primary;
	.fbox-icon {
		.icons {
			font-size: 40px;
			@include size(70px, 70px);
			line-height: 70px;
			color: #333;
		}
	}
}

.light-style {
	color: #fff;
	h1, h2, h3, h4, h5, h6 {
		color: #fff;
	}
}

.bg-success {
	.icon-rating label {
		color: darken($brand-success, 10%);
	}
	.icon-rating input + label:hover, .icon-rating input + label:hover ~ label,
	.icon-rating input:checked ~ label {
		color: #fff;
	}
}

.bg-danger {
	.icon-rating label {
		color: darken($brand-danger, 15%);
	}
	.icon-rating input + label:hover, .icon-rating input + label:hover ~ label,
	.icon-rating input:checked ~ label {
		color: #fff;
	}
}

.bg-warning {
	.icon-rating label {
		color: darken($brand-warning, 15%);
	}
	.icon-rating input + label:hover, .icon-rating input + label:hover ~ label,
	.icon-rating input:checked ~ label {
		color: #fff;
	}
}

.bg-primary {
	.icon-rating label {
		color: darken($brand-primary, 15%);
	}
	.icon-rating input + label:hover, .icon-rating input + label:hover ~ label,
	.icon-rating input:checked ~ label {
		color: #fff;
	}
}

.bg-info {
	.icon-rating label {
		color: darken($brand-info, 15%);
	}
	.icon-rating input + label:hover, .icon-rating input + label:hover ~ label,
	.icon-rating input:checked ~ label {
		color: #fff;
	}
}

/* --- SCSS For Interactive-banner --- */
// Core variables and mixins

.interactive-banner {
	color: #fff;
	position: relative;
	overflow: hidden;
	.interactive-banner-profile {
		position: absolute;
		z-index: 2;
		margin: auto;
		content: "";
		h2 {
			text-transform: uppercase;
			margin: 10px 0 15px;
		}
		small {
			text-transform: uppercase;
		}
	}
	.mask-link {
		position: absolute;
		content: "";
		@include size (100%, 100%);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1;
	}

	.banner-title {
		h2, h3, h4, h5, h6 {
			color: #fff;
		}
	}
	.action {
		.icons {
			&:hover {
				background-color: $brand-success !important;
				border: $brand-success !important;
			}
		}
	}
	&.left {
		.interactive-banner-profile {
			left: 20px;
		}
	}
	&.right {
		.interactive-banner-profile {
			right: 20px;
		}
	}

	&.center {
		.interactive-banner-profile {
			right: 0;
			left: 0;
		}
	}

}

.interactive-banner-v1 {
	.interactive-banner-profile {
		@include size(55%, 120px);
		bottom: 0;
		top: 0;
	}
}

.interactive-banner-v2 {
	.interactive-banner-profile {
		top: 40px;
		@include size(55%, 120px);
		.action {
			padding: 5px 15px;
			margin-bottom: 3px;
			background-color: #fff;
			color: #333;
		}
	}
	&.left {
		.interactive-banner-profile {
			left: 60px;
		}
	}
	&.right {
		.interactive-banner-profile {
			right: 60px;
		}
	}

	&.center {
		.interactive-banner-profile {
			right: 0;
			left: 0;
		}
	}
}

.interactive-banner-v3 {
	.interactive-banner-profile {
		padding: 40px 60px;
		@include size(100%, 100%);
		top: 0;
		.banner-title {

		}
		.action {
			bottom: 60px;
			left: 60px;
			right: 60px;
			content: "";
			position: absolute;
			&:after {
				content: "";
				position: absolute;
				border: 5px solid #fff;
				bottom: -30px;
				left: -30px;
				right: -30px;
				height: calc(100% + 60px);
				width: calc(100% + 60px);
				z-index: -1;
			}
		}

	}
}

.interactive-banner-v4 {
	.interactive-banner-profile {
		width: 100%;
		bottom: 0;
		left: 0;
		padding: 0 30px;
		position: absolute;
		content: "";
		transform: translate3d(0px, 60px, 0px);
		@include transition (opacity 0.35s ease 0s, transform 0.35s ease 0s);
		.action {

			@include opacity(0);
			@include transition (opacity 0.35s ease 0s);
			position: relative;
			background-color: $brand-success;
			margin: 0 -30px;
			padding: 20px 30px;
		}
		img {
			@include opacity(1);
			@include transition (opacity 0.35s ease 0s);
		}
	}
	&:hover {
		.interactive-banner-profile {
			transform: translate3d(0px, 0px, 0px);
			.action {
				@include opacity(1);
			}
			img {
				@include opacity(0.5);
			}
		}
	}
}

.interactive-banner-v5 {
	.interactive-banner-profile {
		@include size(55%, 120px);
		bottom: 0;
		top: 0;
	}
	a.links {
		content: "";
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -30px;
		@include opacity(0);
		@include transition (opacity 0.35s ease 0s, transform 0.35s ease 0s);
		transform: translate3d(0px, -60px, 0px);
	}
	&:hover {
		a.links {
			@include opacity(1);
			transform: translate3d(0px, 0px, 0px);
		}
	}
}

/* --- SCSS For List --- */
// Core variables and mixins

/* class list */
.list-unstyled {
	list-style: none;
	padding-left: 0;
}

.lists {
	li {
		margin-bottom: 10px;
		list-style: none;
		a {
			color: $bo-list-a-color;
			&:hover {
				color: $bo-list-a-color-hover;
				text-decoration: none;
			}
		}
		i {
			color: $bo-list-color;
			margin-right: 10px;
			position: relative;
			top: 2px;
		}
	}
	&.lists-light {
		li {
			&, a, i {
				color: $bo-list-light-a-color;
			}
			a {
				&:hover {
					color: $bo-list-light-a-color-hover;
				}
			}
		}
	}
	&.list-style-circle,
	&.list-style-disc {
		list-style: none;
		padding-left: 20px;
		li {
			position: relative;
			&:before {
				color: $bo-list-color;
				font-family: 'FontAwesome';
				font-size: 10px;
				left: -20px;
				position: absolute;
				top: 4px;
			}
		}
		&.lists-light {
			li {
				&:before {
					color: $bo-list-light-color;
				}
			}
		}
	}
	&.list-style-circle {
		li {
			&:before {
				content: "\f10c";
			}
		}
	}
	&.list-style-disc {
		li {
			&:before {
				content: "\f111";
			}
		}
	}
	&.list-style-square {
		list-style: none;
		padding-left: 20px;
		li {
			position: relative;
			&:before {
				content: "";
				top: 10px;
				left: -15px;
				position: absolute;
				@include size(4px, 4px);
				background-color: $bo-list-color;
			}
		}
		&.lists-light {
			li {
				&:before {
					background-color: $bo-list-light-color;
				}
			}
		}
	}

	&.list-style-number {
		counter-reset: li;
		list-style: outside none decimal;
		padding-left: 20px;
		li {
			display: block;
			padding-left: 5px;
			&:before {
				color: $bo-list-color;;
				content: counter(li, decimal);
				counter-increment: li;
				left: 20px;
				position: absolute;
			}

		}
		&.lists-light {
			li {
				&:before {
					color: $bo-list-light-color;
				}
			}
		}
	}
}

/* --- SCSS For Media --- */

.media-image-v1 {
	border: 10px solid #f5f5f5;
	box-shadow: 0 4px 3px -2px rgba(0, 0, 0, 0.1);
}

.media-image-v2 {
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.media-image-v3 {
	border: 10px solid #f5f5f5;
	position: relative;
	&:before,
	&:after {
		@include size(22%, 15px);
		bottom: 2px;
		box-shadow: 0 9px 20px 4px rgba(0, 0, 0, 0.1);
		content: "";
		position: absolute;
		z-index: -1;
	}
	&:before {
		left: 5px;
	}
	&:after {
		right: 5px;
	}
}

/* --- SCSS For Message --- */
// Core variables and mixins

.alert-box-success {
	@include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
}

.alert-box-info {
	@include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}

.alert-box-warning {
	@include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}

.alert-box-error {
	@include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
}

/* --- SCSS For Mockup-presentation --- */
.mockup {
	font-size: 16px;
	line-height: 32px;
	.heading {
		h1, h2, h3, h4, h5 {
			margin-top: 0;
		}
	}

}

/* --- SCSS For Panel --- */
.panel-blue {
	@include panel-variant($panel-blue-border, $panel-blue-text, $panel-blue-heading-bg, $panel-blue-border);
}

.panel-orange {
	@include panel-variant($panel-orange-border, $panel-orange-text, $panel-orange-heading-bg, $panel-orange-border);
}

.panel-pink {
	@include panel-variant($panel-pink-border, $panel-pink-text, $panel-pink-heading-bg, $panel-pink-border);
}

.panel-cyan {
	@include panel-variant($panel-cyan-border, $panel-cyan-text, $panel-cyan-heading-bg, $panel-cyan-border);
}

.panel-red {
	@include panel-variant($panel-red-border, $panel-red-text, $panel-red-heading-bg, $panel-red-border);
}

.panel-green {
	@include panel-variant($panel-green-border, $panel-green-text, $panel-green-heading-bg, $panel-green-border);
}

.panel {
	&-v1 {
		background-color: transparent;
		position: relative;
		> .panel-heading {
			margin-bottom: 25px;
			padding: 0;
			.panel-title {
				position: relative;
				font-size: $font-size-base + 10;
				color: $theme-color-default;
				text-transform: capitalize;
				font-weight: bold;
				padding: 10px;
				&:after {
					left: 55px;
				}
			}
		}
		.panel-body {
			border: $brand-border;
			@include rounded-corners(3px);
			background-color: $white;
		}
	}
	&-v2 {
		> .panel-heading {
			.panel-title {
				font-weight: 600;
			}
		}
	}
	&-v4 {
		> .panel-heading {
			padding-left: 40px;
			.panel-title {
				font-size: $font-size-base + 13;
				text-transform: capitalize;
				font-weight: 600;
			}
		}
		.panel-body {
			padding: 25px;
			border-top: 1px solid $border-color;
		}
	}
	&-v5 {
		border-top-width: 3px;
		border-top-style: solid;
		.panel-heading {
			padding-left: 35px;
			.panel-title {
				font-size: $font-size-base + 15;
				text-transform: capitalize;
				font-weight: 600;
			}
		}
		.panel-body {
			padding: 25px;
			border-top: 1px solid $border-color;
		}
	}
	&-v6 {
		border-bottom-width: 3px;
		border-bottom-style: solid;
		.panel-heading {
			padding-left: 35px;
			.panel-title {
				font-size: $font-size-base + 13;
				text-transform: capitalize;
			}
		}
		.panel-body {
			padding: 25px;
			border-top: 1px solid $border-color;
		}
	}

}

/* --- SCSS For Parallax --- */
// Core variables and mixins

.parallax {
	padding-top: 150px;
	padding-bottom: 150px;
	text-align: center;
	.parallax-heading {
		h1, h2, h3, h4, h5, h6 {
			text-transform: uppercase;
		}
	}
	&-v1 {
		.parallax-heading {
			h2 {
				margin: 0;
			}
		}
	}
	&-light {
		h1, h2, h3, h4, h5, h6, p {
			color: #FFF;
		}
	}
}

/* --- SCSS For Service --- */
// Core variables and mixins

.icon-combs {
	position: relative;
	.icon-combs-panel {
		i {
			float: left;
			margin: 10px 15px 0 0;
		}
		&.icon-combs-right {
			i {
				float: right;
				margin: 10px 0 0 15px;
			}
		}
	}
	.icon-combs-desc {
		overflow: hidden;
	}
	> li {
		float: left;
		padding-right: 20px;
		position: relative;
		text-align: right;
		width: 50%;
		&.icon-combs-inverted {
			clear: right;
			float: right;
			padding: 0 0 0 20px;
		}
	}
	&.icon-combs-light {
		&:before {
			background: #fff;
		}
		.icon-combs-desc {
			h3, p {
				color: #fff;
			}
		}
	}
	&:before {
		background: #777;
		bottom: 0;
		content: "";
		left: 50%;
		margin-left: -1.5px;
		position: absolute;
		top: 0;
		width: 2px;
	}
}

.icon-wrap-icon {
	@include square(60px);
	display: inline-block;
	background: $brand-primary;
	.fa {
		font-size: 25px;
		margin: 18px 15px;
		color: #fff;
	}
}

.icons {
	text-align: center;
	@include size(40px, 40px);
	line-height: 40px;
	font-size: 20px;
	display: inline-block;
	background-color: $bo-style-icon-bg;
	color: $bo-style-icon-color;
	border: 1px solid transparent;
	&.icons-lg {
		@include size(50px, 50px);
		line-height: 50px;
		font-size: 26px;
	}

	&.icons-sm {
		@include size(32px, 32px);
		line-height: 32px;
		font-size: 14px;
	}
	&.icons-xs {
		@include size(25px, 25px);
		line-height: 25px;
		font-size: 12px;
	}
	&:hover {
		color: $bo-style-icon-hover-color;;
		background-color: $bo-style-icon-hover-bg;
	}

	&.icons-bodered {
		border: 1px solid $bo-style-icon-bodered-color;
		background-color: transparent;
		color: $bo-style-icon-bodered-color;
		&:hover {
			border: 1px solid $bo-style-icon-bodered-hover-color;
			background-color: transparent;
			color: $bo-style-icon-bodered-hover-color;
		}
	}
	&.icons-darker {
		background-color: $bo-style-icon-darker-bg !important;
		color: $bo-style-icon-darker-color !important;
		&:hover {
			background-color: $bo-style-icon-darker-hover-bg !important;
			color: $bo-style-icon-darker-hover-color !important;
		}
	}
	&.icons-light {
		background-color: $bo-style-icon-light-bg !important;
		color: $bo-style-icon-light-color !important;

		&:hover {
			background-color: $bo-style-icon-light-hover-bg !important;
			color: $bo-style-icon-light-hover-color !important;
		}
	}
	&.icons-plain {
		background-color: $bo-style-icon-plain-bg;
		color: $bo-style-icon-plain-color;
		font-size: 28px !important;

		@include size(auto, auto);
		&.icons-lg {
			font-size: 36px !important;
			@include size(auto, auto);
		}
		&.icons-sm {
			font-size: 20px !important;
			@include size(auto, auto);
		}
		&.icons-xs {
			font-size: 14px !important;
			@include size(auto, auto);
		}

		&:hover {
			background-color: $bo-style-icon-plain-hover-bg;
			color: $bo-style-icon-plain-hover-color;
		}
	}
}

.icons-outline {
	@include button-variant-outline($icons-outline-color, $icons-outline-bg, $icons-outline-border, $icons-outline-hover-color, $icons-outline-hover-bg, $icons-outline-hover-border);
	@include icons-outline(primary, $brand-primary, #FFFFFF);
	@include icons-outline(success, $brand-success, #FFFFFF);
	@include icons-outline(info, $brand-info, #FFFFFF);
	@include icons-outline(danger, $brand-danger, #FFFFFF);
	@include icons-outline(warning, $brand-warning, #FFFFFF);
}

.icons-inverse {
	@include button-variant-outline($icons-inverse-color, $icons-inverse-bg, $icons-inverse-border, $icons-inverse-hover-color, $icons-inverse-hover-bg, $icons-inverse-hover-border);
	@include icons-inverse(primary, $brand-primary, #FFFFFF);
	@include icons-inverse(success, $brand-success, #FFFFFF);
	@include icons-inverse(info, $brand-info, #FFFFFF);
	@include icons-inverse(danger, $brand-danger, #FFFFFF);
	@include icons-inverse(warning, $brand-warning, #FFFFFF);
}

.light-style {
	.icons {
		background-color: $bo-light-style-icon-bg;
		color: $bo-light-style-icon-color;

		&:hover {
			color: $bo-light-style-icon-hover-color;;
			background-color: $bo-light-style-icon-hover-bg;
		}
	}

	.icons-bodered {
		border: 1px solid $bo-light-style-icon-bodered-color;
		color: $bo-light-style-icon-bodered-color;
		background-color: transparent;
		&:hover {
			border: 1px solid $bo-light-style-icon-bodered-hover-color;
			color: $bo-light-style-icon-bodered-hover-color;
			background-color: transparent;
		}
	}
	.icons-plain {
		background-color: $bo-style-icon-plain-bg;
		color: #fff;
		&:hover {
			background-color: $bo-style-icon-plain-bg;
			color: $bo-style-icon-plain-color;
		}
	}
}

.icons-default {
	@include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}

.icons-primary {
	@include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}

// Success appears as green
.icons-success {
	@include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

// Info appears as blue-green
.icons-info {
	@include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

// Warning appears as orange
.icons-warning {
	@include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

// Danger and error appear as red
.icons-danger {
	@include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

/* --- SCSS For Table --- */
// Core variables and mixins

.table {
	margin-bottom: 0;
	/*     font-size: 16px; */
}

.table > thead > tr > th {
	border-width: 1px;
	color: #444444;
	font-weight: 600;
}

.table > tbody > tr > td {
	color: #969696;
	vertical-align: middle;
}

.table {
	> thead,
	> tbody,
	> tfoot {
		> tr {
			> td {
				border-top: medium none;
			}
		}
	}
	&-v1 {
		border: 1px solid $border-color;
		> thead,
		> tbody,
		> tfoot {
			> tr {
				> th, td {
					text-align: center;
				}
			}
		}
	}
	&-v2 {
		border: 1px solid $border-color;
		> thead,
		> th {
			border-bottom: 2px solid $border-color;
			background: lighten($border-color, 7%);
		}
		> tbody,
		> tfoot {
			> tr {
				> th, td {
					border: 1px solid $border-color;
				}
			}
		}
	}
	&-v3 {
		border: 0;
		> thead,
		> th {
			border: 0;
			background: lighten($border-color, 7%);
		}
		> tbody,
		> tfoot {
			> tr {
				> th, td {
					border: 0;
				}
			}
			tr:nth-child(even) {
				background: #f9f9f9;
			}
			tr:nth-child(odd) {
				background: #FFF;
			}
		}
	}
	&-v4 {
		> thead,
		> th {
			border-bottom: 2px solid $border-color;
		}
		> tbody,
		> tfoot {
			> tr {
				> th, td {
					border-top: 1px solid $border-color;
				}
			}
		}
	}
}

.table-striped.table-striped-v1 thead {
	background: none repeat scroll 0 0 #dddddd;
}

.table-striped.table-striped-v1 > thead > tr > th {
	border-bottom: medium none;
}

.table-striped.table-striped-v1 > tbody > tr:nth-child(2n+1) {
	> td, > th {
		background: inherit;
	}
}

.table-striped.table-striped-v1 > tbody > tr:nth-child(2n+2) {
	> td, > th {
		background: none repeat scroll 0 0 #f6f6f6;
	}
}

.table-striped.table-striped-v2 {
	border: 1px solid #dddddd;
}

.table-striped.table-striped-v2 {
	> thead,
	> tbody,
	> tfoot {
		> tr {
			> th, td {
				border-top: 1px solid #dddddd;
			}
		}
	}
}

.table-striped.table-striped-v2 > tbody > tr:nth-child(2n+1) {
	> td, > th {
		background: inherit;
	}
}

/* --- SCSS For Tabs --- */
// Core variables and mixins

/* tabs style 1 */
.tab-v1 {
	.nav-tabs {
		margin-right: 130px;
		margin-top: 15px;
		> li {
			> a {
				color: $link-color;
				font-weight: 500;
				font-size: $font-size-base - 2;
				text-transform: uppercase;
				@include rounded-corners(3px);
				padding: 0 15px;
				background: transparent;
			}
			&.active > a {
				color: $bo-tabs-a-color-active;
				border: none;
				background: $theme-color;
			}
		}
	}

	.tab-content {
		padding: 0;
	}
	&.tab-info .nav-tabs > li > a {
	}
}

/* tabs style 2 */
.tab-v2 {
	.tab-content {
		overflow: hidden;
		padding: $bo-tabs-content-padding;
		background: $bo-tabs-content-background;
	}
	> .nav-tabs {
		border-bottom: none;
		> li {
			float: none;
			> a {
				color: $bo-tabs-a-color;
				border: none;
				min-width: 75px;
				border-radius: 0;
				margin: 0 0 2px 0;
				padding: $bo-tabs-a-padding;
				background: $bo-tabs-background;
			}
			&.active > a {
				color: $bo-tabs-a-color-active;
				border: none;
				background: $bo-tabs-background-hover;
			}
		}
	}
	&.tabs-left > .nav-tabs {
		float: left;
	}
	&.tabs-right > .nav-tabs {
		float: right;
	}

}

/*Tab v3
------------------------------------*/

.tab-v3 {
	.tab-content {
		padding: 0;
	}
	.nav-tabs {
		> li {
			border: none;
			padding-right: 25px;
			> a {
				color: $text-color;
				font-size: $font-size-base + 10;
				padding: 10px;
				background: transparent;
				text-transform: capitalize;
				line-height: 1.1;
				margin-bottom: 25px;
			}
			&.active > a {
				color: $black;
				font-weight: bold;
				position: relative;
			}
		}
	}
}

.tab-v4 {
	.nav-tabs {
		border-bottom: 1px solid $border-color;
		> li {
			border: 1px solid $border-color;
			border-bottom: none;
			border-left: none;
			&:first-child {
				border-left:1px solid $border-color;
			}
			> a {
				color: $bo-tabs-a-color;
				padding: $bo-tabs-v4-a-padding;
				font-size: $bo-tabs-v4-a-font-size;
				background: $bo-tabs-background;
				font-family: $headings-font-family;
				&:hover {
					background: inherit;
					color: $bo-tabs-a-color-hover;
				}
			}
			&.active > a {
				color: $bo-tabs-a-color-active;
				background: $gray-darker;;
				border: none;
				&:hover, &:focus {
					color: $bo-tabs-a-color-active;
				}
			}
		}
	}
	.tab-content {
		padding: 20px 0;
		border: none;
	}
}

/*Tab v5
------------------------------------*/
.tab-v5 {
	.nav-tabs {
		display: inline-block;
		> li {
			> a {
				color: $bo-tabs-a-color;
				border: none;
				border-radius: 0;
				font-family: $headings-font-family;
				padding: $bo-tabs-v5-a-padding;
				font-weight: 700;
				font-size: $bo-tabs-v5-a-font-size;
				position: relative;
				letter-spacing: 2px;
				&:hover {
					background: inherit;
					color: $bo-tabs-a-color-hover;
				}
			}
			&.active > a {
				color: $bo-tabs-a-color-active;
				background: inherit;
				border: none;
				&:hover, &:focus {
					color: $bo-tabs-a-color-active;
				}
			}
			&.active a, a:hover, a:focus {
				&:after {
					right: 0;
					left: 0;
					@include size(30px, 2px);
					margin: 0 auto;
					content: " ";
					top: 0;
					background: $brand-primary;
					position: absolute;
				}
			}
		}
	}
	.tab-content {
		padding: 0;
	}
}

/*Tab v6
------------------------------------*/
.tab-blue {
	.nav-tabs {
		> li {
			> a {
				&:hover, &:focus {
					color: $blue;
				}
			}
			&.active > a {
				color: $blue;
			}
		}
	}
	.tab-heading {
		border-top-color: $blue !important;
	}
}

.tab-v6 {
	.tab-heading {
		border-top: 3px solid $theme-color;
		border-bottom: 1px solid $border-color-base;
	}
	h2.category {
		margin: 0;
		float: left;
		text-transform: uppercase;
	}
	.nav-tabs {
		> li {
			&.active {
				background-color: $theme-color;
				position: relative;
				&:before {
					position: absolute;
					left: 50%;
					bottom: -12px;
					margin-left: -10px;
					border: 6px solid transparent;
					border-top: 6px solid $theme-color;
					content: "";
					z-index: 99;
				}
				a {
					color: $white;
				}
			}
			&:first-child {
				border-left: none;
			}

			> a {
				color: $theme-color-default;
				padding-right: 25px;
				text-transform: uppercase;
				line-height: 18px;
				font-weight: 500;
			}
		}
	}
	.tab-content {
		border-top: none;
		border-color: $border-color-base;
	}
}

.tab-v7 {
	.nav-tabs {
		position: relative;
		&:after {
			position: absolute;
			left: 0;
			top: -3px;
			@include size(100%, 3px);
			content: "";
			z-index: 99;
			background-color: $theme-color;
		}
		> li {
			position: relative;
			&:after {
				position: absolute;
				left: 0;
				top: -3px;
				@include size(101%, 3px);
				content: "";
				z-index: 999;
			}
			&:nth-child(1) {
				a {
					&:after {
						background-position: 0 0;
					}
				}
			}
			&:nth-child(2) {
				&:after {
					background-color: #1fb257;
				}
				a {
					&:after {
						background-position: -32px 0;
					}
				}
			}
			&:nth-child(3) {
				&:after {
					background-color: #f97f22;
				}
				a {
					&:after {
						background-position: -60px 0;
					}
				}
			}
			&:nth-child(4) {
				&:after {
					background-color: #03b9c5;
				}
				a {
					&:after {
						background-position: -85px 0;
					}
				}
			}
			&:nth-child(5) {
				&:after {
					background-color: #fd5c6d;
				}
				a {
					&:after {
						background-position: -110px 0;
					}
				}
			}
			&.active {
				background-color: $theme-color;
				position: relative;
				&:before {
					position: absolute;
					bottom: -12px;
					margin-left: -10px;
					border: 6px solid transparent;
					border-top: 6px solid $theme-color;
					content: "";
					z-index: 99;
					left: 50%;
				}
				a {
					color: $white;
				}
				&:nth-child(1) {
					a {
						&:after {
							background-position: 0 -26px;
						}
					}
				}
				&:nth-child(2) {
					a {
						&:after {
							background-position: -32px -26px;
						}
					}
				}
				&:nth-child(3) {

					a {
						&:after {
							background-position: -60px -27px;
						}
					}
				}
				&:nth-child(4) {

					a {
						&:after {
							background-position: -85px -26px;
						}
					}
				}
				&:nth-child(5) {

					a {
						&:after {
							background-position: -110px -26px;
						}
					}
				}
			}
			&:first-child {
				border-left: none;
			}
			> a {
				color: $text-color;
				font-size: $font-size-base - 1;
				font-weight: 500;
				position: relative;
				line-height: 21px;
				padding-left: 40px;
				&:before {
					position: absolute;
					left: 0;
					top: -3px;
					@include size(1px, 106%);
					content: "";
					z-index: 999;
					background-color: $border-color;
				}
				&:after {
					background: url(#{$image-theme-path-base}icon-tab.png) no-repeat 0 0;
					position: absolute;
					left: 10px;
					top: 20px;
					@include size(25px, 20px);
					content: "";
					z-index: 999;
				}
			}
		}
	}
	.tab-content {
		padding: 0;
	}
}

 