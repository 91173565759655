
@mixin hide-text() {
  font: #{0/0} a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

// New mixin to use as of v3.0.1
@mixin text-hide() {
  @include hide-text;
}
