.owl-carousel {
	display: block !important;
}

.category-info {
	padding: 0;
	.image {
		padding: 0;
		margin-bottom: 19px;
	}
}

.refine-search {
	ul, ol {
		list-style: none;
		li {
			padding: $padding-small-vertical 0;
			padding-left: 10px;
			margin-right: 10px;
			margin-left: 10px;
			line-height: normal;
			position: relative;
			float: left;
			&:before {
				content: "";
				position: absolute;
				@include size(3px, 3px);
				top: 50%;
				left: 0;
				background-color: $theme-color-default;
			}
			a {
				color: $text-color;
			}
		}
	}
	h3 {
		color: #474747;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.style-panel {
	.panel {
		margin: 15px 0;
		border: 1px solid #ddd;
	}
	.panel-collapse {

	}
	.panel-heading {

	}
}

//sider bar
.sidebar {
	.products-block {
		border: $brand-border;
		@include rounded-corners(3px);
	}
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;

	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;

		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
			.compare, .cart {
				display: inline-block;
			}
			.cart {
				margin-right: 10px;
			}
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
		.rating {
			margin: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}

}

.product-info {
	.product-meta {
		.btn-sm, .btn-group-sm > .btn {
			padding: 0 15px;
		}
	}
}

.subcategories {
	margin-bottom: -1px;
	background-color: $product-filter-bg;
}

.product-filter {
	color: $bk;
	text-transform: uppercase;
	font-size: $product-filter-font-size;
	font-weight: 500;
	span {
		margin-right: 7px;
	}
	.filter-right > div {
		padding-left: 10px;
		display: flex;
		align-items: center;
		margin-right: 2px;
	}

	.display {
		float: left;
	}
	.form-control {
		display: inline-block;
		margin: 0 5px;
		@include size(auto, 36px);
		text-transform: capitalize;
		height: 28px;
		padding: 0 7px;
	}
	.btn-switch {
		display: inline-block;
		color: #969696;
		background: transparent;
		height: 28px;
		margin: 0;
		padding: 0;
		line-height: 35px;
		font-weight: 500;
		i {
			font-size: 28px;
			padding-right: 10px;
		}
		&:hover, &.active {
			color: $theme-color-default;
			@include box-shadow(none);
		}
	}
	.sort {
		.selectric-items {
			left: auto;
			right: 0;
		}
	}
}

.product-list {
	.description {
		display: block;
	}
	.product-meta {
		padding-top: 20px;
		padding-left: 0;
	}
	.product-block {
		width: calc(100% - 2px);
		display: flex;
		outline: 1px #eee solid;
		margin: 1px;
		.block-img {
			margin-right: 15px;
			img {
				max-height: 400px;
			}
		}
		.name {
			height: auto;
			font-weight: 700;
		}
		.price {
			margin-bottom: 15px;
		}
		.name {
			a {
				padding: 0;
				height: auto !important;
			}
		}
		.cart .btn {
			width: auto;
			padding: 0 15px;

		}
	}
}

.cart .btn.fa-bar-chart-o {
	font-family: 'FontAwesome' !important;
	font-size: 14px !important;
	font-weight: 400 !important;
}

.product-grid {
	.products-block {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 1px;
	}
	.product-block {
		width: calc((100% / 4) - 1px);
		outline: 1px $lg solid;
		border-radius: 0;
		position: relative;
		left: 1px;
		.cart {
			padding: 15px 15px 0 15px;
			display: flex;
			justify-content: space-between;
			.btn {
				width: auto;
				padding: 0 15px;
			}
		}
	}
}

.owl-carousel .owl-stage {
	.product-block {
		width: 100% !important;
		left: 0;
		border: 1px #eee solid;
		outline: none;
		.price {
			margin-top: 15px;
		}
	}
	.border {
		padding: 1px 0;
	}
}

.product-info {
	.box-product-infomation {
		margin-top: 20px;
	}
	.title-product {
		font-size: $font-size-base + 3;
		margin-top: 0;
		text-transform: capitalize;
		font-weight: 600;
	}
	.image-additional {
		overflow: hidden;
		.owl-carousel {
			margin-bottom: 0 !important;
		}
		.owl-wrapper {
			background: transparent;
			img {
				width: 100%;
			}
			.owl-item {
				background: $wh;
			}
		}
	}
	.image-additional .item {
		border: 1px solid $border-color;
		text-align: center;
		margin: 0 2px;
		background-color: $white;
		position: relative;
		a.active {
			&:before {
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $theme-color;
				content: "";
				left: 0;
				z-index: 1;
				top: -1px;
			}
			&:after {
				position: absolute;
				left: 50%;
				margin-left: -8px;
				border: 6px solid transparent;
				border-bottom: 6px solid $theme-color;
				content: "";
				z-index: 1;
				top: -12px;
			}
		}
	}
	.thumbs-preview {
		margin-top: 20px;
		a + a {
			margin-left: 10px;
		}
	}
	.vertical {
		&.image-additional {
			.carousel-control {
				display: none;
			}
		}
		&:hover {
			.carousel-control {
				display: block;
			}
		}
		.center {
			left: 0;
			right: 0;
			margin: 0 auto;
		}
		.top {
			top: 0;
			bottom: auto;
		}
		.bottom {
			top: auto;
			bottom: 0;
		}
	}
	.horical {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;

		&:hover {
			.carousel-control {
				display: block;
			}
		}
	}
	.check-box {
		padding-right: 5px;
		font-size: $font-size-base - 4;
	}
	.image {
		position: relative;
		text-align: center;
		overflow: hidden;
		border: 1px solid $border-color;
	}
	.share {
		@include clearfix;
		padding: 20px 0;
		display: none;
	}
	.option-image {
		margin-top: 3px;
		margin-bottom: 10px;
		label {
			display: block;
			width: 100%;
			height: 100%;
		}
		img {
			margin-right: 5px;
			margin-left: 0;
			border: 1px solid #cccccc;
			cursor: pointer;
		}
	}
	.options {
		margin-bottom: 20px;
	}
	.wrap-qty {
		border: 1px solid $border-color;
		height: 32px;
		background-color: $white;
		padding: 3px;
		> div {
			display: table-cell;
			float: left;
		}
	}
	.quantity {
		display: flex;
		gap: 8px;
	}
	.quantity-adder {
		display: flex;
		position: relative;
		background-color: $white;
		@include border-radius($border-color, 3px);
		width: max-content;

		.add-action {
			@include size(40px, 39px);
			color: $black;
			display: block;
			position: relative;
			cursor: pointer;
			font-size: 12px;
			line-height: 39px;
			text-align: center;
		}
		.quantity-number {
			input {
				@include size(40px, 39px);
				text-align: center;
				@include inline-block;
				@include rounded-corners(0);
				box-shadow: none;
				padding: 0;
				border: none;
				color: $black;
				font-size: 16px;
			}
		}
		.minimum {
			line-height: 38px;
			font-size: 11px;
			margin-left: 10px;
		}
	}
	.product-meta {
		display: flex;
		flex-direction: column;
		gap: 16px;

		a {
			color: #ccd6dd;
			font-weight: 500;
		}
		.cart {
			padding-bottom: 0;
		}
		.cart, .compare {
			display: block;
			.btn-kn-red {
				padding: 0 37px;
			}
			#button-cart-credit {
				padding: 0 5px;
			}
		}
	}
}

.product-markdown-view {
	.cheaper-item {
		padding: 20px;
		border: 1px solid $dg;
		max-width: 570px;

		&:not(.general) {
			border: 1px solid $gray;

			.price {
				.price-new {
					font-size: 18px;
				}
			}
		}
	}
	.cart {
		.btn {
			width: 100%;
		}
	}
	.cheaper-products, .cheaper-items, .cheaper-item, .cheaper-item-info {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.cheaper-products {
		margin-top: 20px;
	}
	.cheaper-products, .cheaper-item {
		&__title {
			font-size: 16px;
			font-weight: 600;
		}
		&__reason {
			color: $gray;
		}
		&__status {
			font-weight: 600;
			color: $gray;
		}
	}
	.wrapper-btn-credt {
		display: flex;
		gap: 8px;

		.credit, button {
			width: 100%;
		}
	}
}

.zoomWindowContainer .zoomWindow {
	left: 500px;
}

.horital {
	position: relative;
}

.owl-carousel {
	.owl-wrapper-outer {
		border: none;
		@include box-shadow(none);
		@include rounded-corners(0);
	}
}

.related {
	.owl-item {
		.product-col {
			border-left: 1px solid $border-color;
		}
		&:first-child {
			.product-col {
				border-left: 1px solid transparent;
			}
		}
	}
}

#input-captcha, #input-payment-captcha {
	margin-bottom: 10px;
}

#form-review-container {
	.set-rating {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		margin-top: 6px;
		margin-bottom: 2px;
		height: 16px;
		width: max-content;
		i {
			margin: 0px;
			display: block;
			float: left;
			padding: 0px 4px 0px 0px;
			font-size: 20px;
			cursor: pointer;
			color: $lg;
			&.selected {
				color: $y;
			}
		}
		&:hover i {
			color: $lg;
		}
		& > i:hover, & > i:hover ~ i {
			color: $y;
		}
	}
	.recaptcha_container {
		.control-label {
			display: none;
		}
	}
}

#review {
	.review-item {
		margin-bottom: 15px;
		.review-header {
			display: flex;
			border-bottom: 2px solid $tcg;
			.review-left-header {
				width: 80%;
				display: flex;
				flex-wrap: wrap;
				font-size: 16px;
				font-weight: 400;
				text-transform: uppercase;
				& > div {
					margin: 10px 15px;
					&.rating {
						display: flex;
						align-items: center;
						color: $y;
						.fa {
							margin-right: 2px;
						}
					}
				}
			}
			.review-right-header {
				width: 20%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin: 10px;
			}
		}
		.review-main {
			.review-title {
				margin: 20px 10px 5px 15px;
				font-size: 16px;
				font-weight: 400;
				text-transform: uppercase;
				color: $tcg;
			}
			.review-text {
				margin: 0 15px;
				&:last-child {
					margin-bottom: 10px;
				}
			}
		}
	}
}

.prod_rating {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	.stars {
		color: $y;
		font-size: 16px;
		margin-right: 15px;
	}
	#scr_revs, #scr_newrev {
		font-size: 16px;
		&:hover {
			cursor: pointer;
			color: $tcg;
		}
	}
	#scr_revs {
		padding-right: 10px;
		margin-right: 10px;
		border-right: 1px solid $tcg;
	}
}

.product-block {
	&.item-default {
		.product-meta {
			position: relative;
		}
	}
	.rating {
		color: $y;
		font-size: 14px;
	}
}

.product-list {
	.rating {

	}
}

.product-grid {
	.rating {
		position: absolute;
		top: 5px;
		left: 0;
		text-align: center;
		width: 100%;
	}
}

.stickers {
	position: absolute;
	top: 10px;
	left: 5%;
	width: 90%;
	height: unset;
	z-index: 100;
	display: flex;
	flex-wrap: wrap;
	.sticker {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: fit-content;
		padding: 4px 8px;
		margin: 3px 3px 3px 0;
		border-radius: 4px;
		svg {
			width: 15px;
			height: 15px;
			margin-right: 10px;
			fill: $white
		}
		span {
			font-size: 16px;
			line-height: 20px;
			color: $white;
		}
		&.black_friday {
			background-color: #424242;
		}
		&.discontinued {
			background-color: #888;
		}
		&.markdown-product {
			background-color: $green;
			@include rounded-corners(3px);
			padding: 4px 8px;
		}
	}
}

.prodDescr {
	.col-2.row {
		width: 50%;
		border-top: 1px dashed #ebebeb;
		padding: 20px 15px;
		word-spacing: 0;
		font-size: 0;
		display: inline-flex;
		.desc-desc {
			font-size: 15px !important;
		}
		.desc-img {
			margin-right: 15px;
			flex: 1 0 30%;
			img {
				border-radius: 20px;
				border: 4px solid #ddd;
				max-width: 100%;
			}
		}
	}
	@media (max-width: 1199px) {
		.col-2.row {
			width: 100%;
		}
	}
}