/* links style */
ul {
	&.links {
		padding: 0;
		position: relative;
		margin: 0;
		li {
			display: inline;
			line-height: normal;
			position: relative;
			a {
				padding-left: 10px;
				color: $text-color;
				i {
					margin-right: 5px;
				}
				&:hover {
					color: $theme-color;
				}
			}
		}
	}
	&.list {
		padding: 0;
		position: relative;
		margin: 0;
		li {
			list-style: none;
			line-height: 30px;
			a {
				color: $text-color;
				&:hover {
					color: $theme-color;
				}
			}
		}
	}

}

.tree-menu {
	padding: 0;
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		li {
			padding: 12px 20px;
			border-top: 1px solid $border-color;
			&:hover {
				background-color: $theme-color;
				color: $white;
				@include rounded-corners(3px);
				position: relative;
				a {
					color: $white;
				}
				.accordion-heading {
					.fa {
						color: $white;
					}
				}
			}
			&:first-child {
				border-top: none;
			}
			.accordion-heading {
				cursor: pointer;
				.fa {
					font-size: $font-size-base - 5;
					color: $theme-color-default;
				}
			}
			&.open a, a {
				color: $black;
				text-transform: capitalize;
				font-size: $font-size-base + 2;
			}
		}
	}
	ul ul {
		padding-left: 20px;
		li {
			list-style: none;
			border: none;
			padding: 8px 0;
			&:hover {
				&:after, &:before {
					content: none;
				}
			}
			a {
				color: $text-color;
				font-weight: normal;
			}
		}
	}
	.vertical {
		.dropdown-submenu:hover {
			> .dropdown-menu {
				display: block;
			}
		}
		.dropdown-menu {
			top: 0;
			left: 100%;
			width: 100%;
		}
	}
}

.list-group-item {
	ul {
		padding-left: 20px;
		li {
			list-style: none;
			padding: 8px 0;
			a {
				color: $theme-color-tertiary;
			}
		}
	}
}

div.required .control-label:after {
	color: #f00;
	content: " *";
	font-weight: bold;
}

.noborder {
	.box {
		border: none !important;
	}
}

.white {
	color: $white;
}

.red {
	color: #f44336;
	line-height: 20px;
}

.table-responsive {
	.btn-primary {
		padding: 0 14px;
	}
}