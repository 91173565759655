// banner builder
.banner-wrapper-margin {
	.banner-wrapper {
		margin-bottom: 20px;
	}
}

.image-item-margin {
	.image-item {
		margin-bottom: 20px;
	}
}

.nomargin .box {
	margin-bottom: 0;
}

/* slidelayer */
.layerslider-wrapper {
	overflow: hidden;
	&:hover {
		.tp-rightarrow {
			right: 20px !important;
		}
		.tp-leftarrow {
			left: 20px !important;
		}
	}
}

.defhome {
	margin-top: -31px;
}

.tparrows {
	position: absolute;
	top: 50% !important;
	z-index: 9999;
	cursor: pointer;
	display: block;
	@include size(45px, 45px);
	@include transition(all 0.3s ease-in-out 0s);
	font-size: 0;
	&:before {
		content: "";
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		z-index: 12;
		background: url("#{$image-theme-path}bullet4.png") no-repeat 0 -2px;
		@include size(40px, 35px);
		@include transition(all 0.35s ease 0s);
		display: block;
	}
	&.tp-rightarrow {
		&:before {
			background-position: -50px -2px;
		}
	}
}

.tp-leftarrow {
	left: -50px !important;
}

.tp-rightarrow {
	right: -50px !important;
}

.tp-bullets.simplebullets .bullet:hover,
.tp-bullets.simplebullets .bullet.selected {
	background-color: $theme-color;
}

.tp-bullets.simplebullets.round .bullet:hover,
.tp-bullets.simplebullets.round .bullet.selected,
.tp-bullets.simplebullets.navbar .bullet:hover,
.tp-bullets.simplebullets.navbar .bullet.selected {
	background-color: $tcg;
}

.effect-carousel-v2 {
	.carousel-controls-v1 {
		position: absolute;
		right: 0;
		.carousel-control {
			opacity: 0;
			@include transition(all 0.5s ease-in-out 0s);
			top: -20px;
			&.left {
				left: -63px;
			}
		}
	}
	&:hover {
		.carousel-control {
			opacity: 1;
			top: 2px;
		}
	}
}

.effect-carousel {
	.carousel-control {
		@include opacity(0);
		@include transition(all 0.5s ease-in-out 0s);
		&.left {
			left: -45px;
		}
		&.right {
			right: -45px;
		}
	}
	&:hover {
		.carousel-control {
			@include opacity(1);
			&.left {
				left: 0;
			}
			&.right {
				right: 0;
			}
		}
	}
}

/* tablist-v1 */
.tablist-v1 {
	.product-grid {
		.products-row {
			.product-col {
				&:last-child {
					border-right: 1px solid $border-color;
					@include rounded-corners(3px);
				}
			}
		}
	}
}

.featured-category {
	.panel-body {
		overflow: hidden;
		padding: 0;
	}
	ul {
		margin: 0;
		li {
			display: inline-block;
			width: 33%;
			position: relative;
			&:before {
				right: 0;
				background: $border-color;
				content: "";
				@include size(1px, 200%);
				position: absolute;
				top: -20px;
			}
			&:after {
				left: 25px;
				background: $border-color;
				content: "";
				@include size(87%, 1px);
				position: absolute;
				top: 0;
			}
			&:nth-child(1), &:nth-child(2), &:nth-child(3) {
				&:after {
					content: none;
				}
			}
			&:nth-child(3n) {
				&:before {
					content: none;
				}
			}
			.image {
				margin: 5px 0 25px 0;
			}
			.caption {
				overflow: hidden;
				padding: 30px 20px;
				h6 {
					font-weight: normal;
					font-size: $font-size-base + 2;
					a {
						color: $black;
					}
				}
			}
			img {
				width: 120px;
			}
		}
	}
}

.category {
	margin: 0;
	.box-heading {
		padding: 15px 15px 15px 45px;
		background: url("../image/bg_category.png") no-repeat 20px 50% $tcg;
		h3 {
			margin: 0;
			padding: 0;
			span {
				color: #fff;
				display: block;
				font-size: 16px;
				font-weight: normal;
				min-height: 16px;
				text-transform: uppercase;
			}
		}
	}
	.box-content {
		border: 1px solid #ebebeb;
		border-top: 0;
		ul.list-group {
			margin: 0;
			& > li:hover {
				& > a {
					color: $tcg;
				}
			}
			li {
				position: relative;
				line-height: 35px;
				list-style: none;
				border-bottom: 1px solid $lg;
				&:last-child {
					border: 0;
				}
				&.hadchild ul {
					padding: 0;
					border-left: 3px solid $tcg;
					border-top: 1px solid $lg;
					li {
						list-style: none;
						padding: 0;
						a {
							padding: 8px 20px 8px 30px;
						}
					}
					&.ttopen {
						& > a {
							color: $tcg;
						}
					}
				}
				a {
					background: none;
					border-right: 0;
					border-top: 0;
					color: #444444;
					cursor: pointer;
					display: block;
					font-size: 14px;
					font-weight: normal;
					line-height: 25px;
					text-decoration: none;
					border-radius: 0;
					padding: 8px 10px;
					margin-right: 42px;
					span {
						font-family: 'Open Sans', sans-serif;
						font-weight: 400;
						font-size: 14px;
						word-wrap: break-word;
					}
					&:hover {
						color: $tcg;
					}
				}
				.active {
					& > a {
						color: $tcg;
						background: $wh;
					}
				}
				.button-view {
					position: absolute;
					right: 15px;
					top: 3px;
					font-size: 16px;
					cursor: pointer;
					font-family: 'FontAwesome';
					&.ttclose:before {
						content: '\f067';
					}
					&.ttopen:before {
						content: '\f068';
					}
				}
			}
		}
	}
}

.category-top {
	.category-image {
		margin: 0;
		overflow: hidden;
		text-align: center;
	}
	.category-des {
		margin-left: 220px;
		font-size: 12px;
	}
}