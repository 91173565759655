
.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.price {
	font-family: $product-price-font-family;
	font-weight: bold;
	color: $tcg;
	.price-new {
		color: $product-price-color;
		font-size: 20px;
		line-height: 1;
	}
	.price-old {
		text-decoration: line-through;
		color: #777;
		line-height: 1;
	}
	// page product detail
	&.detail {
		display: flex;
		gap: 8px;
		align-items: flex-end;

		.price-new {
			font-size: $font-size-base + 11;
		}
		ul {
			margin: 0;
		}
	}
}

/* product style */
.product-v1 {
	.products-row {
		.product-col {
			border-left: 1px solid $border-color;
			&:first-child {
				border-left-color: transparent;
			}
		}
	}
	.product-block {
		.bottom {
			@include opacity(0);
			@include transition (all 400ms ease 0s);
			bottom: 60px;
			left: 20px;
			width: 100%;
			position: absolute;
			z-index: 999;
		}
		&:hover {
			.product-meta {
				.bottom {
					bottom: 120px;
					@include opacity(1);
				}
			}
		}
	}
}

.product-v3 {
	.product-block {
		.description {
			display: block;
			padding: 10px 0 0;
		}
		.action {
			display: none;
		}
		.price-new {
			font-size: $font-size-base + 5;
		}
		.price-old {
			font-size: $font-size-base + 1;
		}
	}
}

.product-v4 {
	padding: 0;
	.products-row {
		.border {
			border-bottom: $brand-border;
			border-right: $brand-border;
		}
		&.last {
			.border {
				border-bottom: transparent;
			}
		}
	}
	.product-block {
		&:hover {
			@include box-shadow(none !important);
		}
		.block-img {
			float: left;
			margin-right: 10px;
		}
		.name {
			margin: 5px 0;
		}
		.product-meta {
			padding: 20px 20px 0 0;
		}
		.bottom {
			display: none;
		}
	}
}

.product-block {
	position: relative;
	background-color: $white;
	@include transition(all 0.4s ease 0s);
	.description {
		display: none;
	}
	.product-meta {
		line-height: 25px;
		padding: 0 20px 20px;
	}
	.bottom {
		display: block;
	}
	.name {
		margin: 10px 0;
		font-size: $font-size-base;
		font-family: $font-family-base;
		text-transform: inherit;
		overflow: hidden;
		font-weight: 400;
		a {
			color: $black;
			font-size: 16px;
			line-height: 22px;
			padding: 10px 0;
			display: inline-block;
			&:hover {
				color: $tcg;
			}
		}
	}
	.image {
		position: relative;
		overflow: hidden;
		@include transition(all 0.4s ease 0s);
		padding: 20px;
		img {
			opacity: 1;
			@include transition(filter 0.2s ease-in-out);
		}
		.quickview {
			visibility: hidden;
			opacity: 0;
			@include transition(opacity 0.2s ease-in-out);
			position: absolute;
			top: calc(50% - 17px);
			left: calc(50% - 20px);
		}
	}
	.top {
		position: relative;
		@include transition(all 0.4s ease 0s);
		.price-new {
			color: $tcg;
		}
		.price-old {
			color: #777;
		}
	}
	&:hover {
		.image {
			img {
				opacity: .8;
			}
			.quickview {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

.cart {
	position: relative;
	@include transition(all 0.4s ease 0s);
	padding-bottom: 15px;
	.btn {
		width: 289px;
	}
	.icon-cart {
		background: url(#{$image-theme-path-base}icon-shopping.png) no-repeat scroll center center;
		display: block;
		@include size(16px, 17px);
	}
}

.product-label {
	color: $product-label-color;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	position: absolute;
	&.ribbon {
		position: absolute;
		right: 0;
		top: 0;
		@include size(40px, 40px);
		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 0;
			height: 0;
			border-top: 40px solid $gray-dark;
			border-left: 40px solid transparent;
		}
		.product-label-special {
			display: block;
			text-align: center;
			height: 100%;
			padding-top: 2px;
			@include rotate(45deg);
		}
	}
	&.ribbon2 {
		width: 85px;
		height: 88px;
		overflow: hidden;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 99;
		display: block;
		font-family: $headings-font-family;
		font-size: 10px;
		.product-label-special {
			display: block;
			text-transform: uppercase;
			text-align: center;
			@include rotate(45deg);
			position: relative;
			padding: 6px 0;
			left: -2px;
			top: 12px;
			width: 120px;
			background-color: $brand-warning;
			color: $black;
			@include box-shadow(0px 0px 3px rgba(255, 255, 255, 0.3));
		}
	}
	&.ribbon1 {
		width: 85px;
		height: 88px;
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 99;
		display: block;
		font-family: $headings-font-family;
		font-size: 10px;
		.product-label-special {
			display: block;
			text-transform: uppercase;
			text-align: center;
			@include rotate(-45deg);
			position: relative;
			padding: 6px 0;
			left: -32px;
			top: 15px;
			width: 120px;
			background-color: $brand-warning;
			color: $black;
			@include box-shadow(0px 0px 3px rgba(255, 255, 255, 0.3));
		}
	}
	&.bts {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 46px;
		height: 20px;
		position: absolute;
		top: 10px;
		background-color: $theme-color;
		right: 10px;
		border-radius: 2px;
		line-height: 20px;
		.product-label-special {
			display: block;
			width: 100%;
			height: 100%;
			position: relative;
			&:before {
				content: "";
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 4px solid $theme-color;
				height: 0;
				width: 0;
				position: absolute;
				left: 42%;
				bottom: -4px;
			}
		}
	}
	&.sale {
		text-align: center;
		position: absolute;
		top: 13px;
		background-color: $green;
		right: 15px;
		@include rounded-corners(3px);
		z-index: 98;
		padding: 4px 8px;
	}
	&.discontinued {
		left: 15px;
		top: 13px;
		background: #999;
		border-radius: 3px;
		z-index: 98;
		padding: 4px 8px;
	}

	&.sale-border {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 40px;
		height: 20px;
		position: absolute;
		top: 15px;
		border: 2px solid #d95e41;
		right: 10px;
		color: #474747;
	}
	&.flag {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 60px;
		height: 30px;
		position: absolute;
		top: 15px;
		background-color: $brand-success;
		left: 0;
		line-height: 30px;
		&:before {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			right: -15px;
			top: 0;
			border-top: 15px solid $brand-success;
			border-right: 15px solid transparent;
		}
		&:after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			right: -15px;
			bottom: 0;
			border-bottom: 15px solid $brand-success;
			border-right: 15px solid transparent;
		}
	}
}

.cart.pull-left {
	float: none !important;
	margin-bottom: 5px;
}
