body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {

	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $font-family;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

/* search */
#search {
	input.form-control {
		min-height: 54px;
		border: none;
	}
	.btn-search {
		@include size(16px, 16px);
		display: block;
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAPCAYAAADtc08vAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExOERCQjhGNzExQzI0RkZBQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0RjE3NDU1ODUyREExMUU1QjAxOEVBNEI5MEVDNEMwOCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0RjE3NDU1NzUyREExMUU1QjAxOEVBNEI5MEVDNEMwOCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IFdpbmRvd3MiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MjcwMkRFNEJBMzlFNTExQjc5NkI1Q0VDRTFFODNGNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExOERCQjhGNzExQzI0RkZBQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PqtFazcAAADbSURBVHjaYmDADYSAuAWIrwLxfyB+C8QbgdiVgQhgD8SvgHgalC0KxLpAnAA1cDI+zVpA/AGIvXHIcwLxXiCuxmXAdiDuJeBCaSB+DsQS6BIggT9Q5xICS4E4jQmL80HgGhEG3AFidSYsEsxQTAiA1aAbcBNKmxFhgAEulx4A4tVEaP4ATSsYwAmacLqAmA2LPCiA7wFxPq7Udx6IfwLxZyC+BMSNQBwFxJnQkAfJtePSfAaqwBsa1xOgtsGS8jogtsGmmR9NM0kApPkkuZoZoAEFcpofOZoBAgwAnBkse1j7LyUAAAAASUVORK5CYII=) no-repeat;

	}
}

.form-control::-moz-placeholder {
	color: $link-color;
}

// module cart
#cart {
	text-align: right;
	padding: 20px 15px;
	a {
		position: relative;
	}
	.icon-cart {
		background: $tcg;
		color: $wh;
		padding: 9px;
		font-size: 28px;
		text-align: center;
		@include size(55px, 47px);
		display: inline-block;
		position: relative;
		margin-left: 8px;
	}
	.wrap-cart {
		display: inline-block;
		color: $white;
		float: right;
		span {
			position: relative;
			top: 13px;
		}
		.cart-total {
			font-weight: bold;
			text-transform: none;
		}
	}
	.dropdown-menu {
		min-width: 350px;
		left: auto;
		right: 0;
		padding: 0;
		margin: 0;
		text-transform: inherit;
		color: $black;
		font-size: $font-size-base;
		li {
			&:first-child {
				.table td {
					padding: 10px;
				}
			}
			a {
				color: $black;
				text-transform: capitalize;
				font-weight: normal;
			}
		}
		.table-cart {
			background: #607d8b;
			.btn {
				border: 1px solid rgba(255, 255, 255, 0.54);
				color: $white;
				text-transform: uppercase;
				padding: 0 7px;
				&:hover {
					background: $white;
					color: $black;
				}
			}
			.table {
				> tbody {
					> tr {
						> td {
							color: $white;
							padding: 5px;
							/* border:none; */
							border-bottom: 1px dotted #71909f;
						}
					}
				}
			}
		}
	}
	.price-cart {
		color: $theme-color;
	}
	.btn-remove {
		font-size: 11px;
		@include size(14px, 14px);
		background: $black;
		color: $white;
		font-weight: normal;
		line-height: 14px;
		padding: 0;
		text-align: center;
		&:hover {
			background: $theme-color;
		}
	}
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}

/* pagination */
.pagination {
	li {
		span, a {
			@include border-radius($border-color, 3px)
		}
	}
}