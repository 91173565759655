@media screen and (max-width: $screen-md-min) {
	.row-offcanvas {
		position: relative;
		@include transition(all 0.25s ease-out);
		&.active {
			.menuwall {
				visibility: visible;
				opacity: 1;
			}
		}
	}
	.row-offcanvas-left {
		left: 0;
	}
	.row-offcanvas-left.active {
		left: 70%;
	}
	.sidebar-offcanvas {
		position: absolute;
		top: 0;
		width: 70%;
		left: -70%;
		@include transition(all 0.25s ease-out);
		ul, ol {
			padding: 0;
			li {
				list-style: none;
			}
		}
		&.active {
			left: 0;
		}
	}
	.bottom-offcanvas {
		border-top: 1px solid $border-color-base;
		padding: 10px 0;
	}
}

/*offcanvas-menu*/
#offcanvasmenu {
	.nav .caret {
		border-bottom-color: $white;
		border-top-color: $white;
		margin-left: 5px;
		display: none !important;
	}
	.pavo-widget .widget-heading {
		margin-top: 15px;
	}
	.navbar-nav {
		float: none;
		margin: 0;
		> li {
			float: none;
			background-color: $wh;
			border-right: none;
			&:hover:after {
				display: none;
			}
			.click-canavs-menu, .click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 13px 16px;
				font-size: 16px;
				position: absolute;
				right: 0;
				z-index: 1;
				&.fa-minus-square:before {
					content: '\f068';
				}
				&.fa-plus-square:before {
					content: '\f067';
				}
			}
			li.dropdown-submenu {
				.click-canavs-menu {
					padding: 10px 25px;
				}
				li.dropdown-submenu {
					> .click-canavs-menu {
						padding: 10px 15px;
					}
				}
			}
			> a {
				display: block;
				@include box-shadow(inset 0 -1px rgba(0, 0, 0, 0.2));
				text-transform: uppercase;
				color: $bk;
			}
			.panel-v4 {
				margin: 0;
				.panel-body {
					padding: 0 0 0 15px;
					ul.cat_all {
						li {
							position: relative;
							padding: 5px 0;
							background: $wh !important;
							.click-allcat {
								top: 0;
								right: -10px;
								color: $bk;
							}
							.children {
								display: none;
							}
							&.haschildren.opened {
								.children {
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}
	.dropdown-menu {
		border: 0 none;
		border-radius: 0;
		box-shadow: none;
		display: none;
		float: none;
		margin: 0;
		padding: 0 10px;
		position: static;
		visibility: visible;
		width: auto !important;
		min-width: 160px;
		@include opacity (1);
		a {
			font-size: 13px;
			padding: 5px 0;
			display: block;
			color: $bk;
			margin-right: 30px;
		}
	}
	.w-product {
		padding: 0;
	}
	.product-block {
		border: none;
		padding: 0;
		.image {
			float: none;
			margin: 0;
		}
	}
}

.canvas-menu {
	margin: 8px;
}

.menuwall {
	visibility: hidden;
	@include opacity(0);
	background: rgba(0, 0, 0, .5);
	position: fixed;
	@include size(100vw, 100vh);
	z-index: 11000;
	left: 70vw;
	@include transition(all 0.25s ease-out);
	.menuclose {
		color: $wh;
		font-size: 36px;
		line-height: 27px;
		padding: 20px;
		width: 70px;
		height: 70px;
		text-align: center;
		position: absolute;
		top: calc(50vh - 35px);
		right: calc(85vw - 35px);
	}
}