@media (min-width: 1921px) {
	.container {
		width: 100%;
		max-width: 1812px;
		margin: 0 auto !important;
	}
	.bannercontainer {
		max-width: 1812px;
		margin: 0 auto !important;
	}
}
@media (min-width: $screen-md) {
	.top-contact {
		padding-top: 20px;
	}
}

@media (min-width: $screen-sm) {
	.home1 {
		.layerslider-wrapper {
			margin-top: -31px;
		}
	}
	.mainbanner {
		position: relative;
		top: -32px;
		margin-bottom: -32px;
	}
}

@media (max-width: $screen-md-max) {
	.refine-search .catgrid .category-home {
		width: calc((100% / 4) - 20px);
	}
	.col-md-12 {
		.product-grid .product-block {
			width: calc((100% / 3) - 1px);
		}
	}
	#powered {
		.payment-img {
			float: right;
		}
		.inside {
			display: inline-block;
			float: left;
		}
	}
	.pav-megamenu .navbar-nav > li > a {
		padding: 16px 19px;
	}
	.productdeals-v1 {
		.item-countdown {
			li {
				width: 35px;
				margin: 0 7px 20px;
			}
		}
		.product-block {
			.product-meta {
				padding: 0 10px 20px;
			}
		}
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.feature-box-v1 {
		padding: 12px 20px;
		.fbox-body.white strong {
			font-size: 18px;
		}
	}
	.home3 {
		.featured-category {
			ul li {
				.caption {
					padding: 20px 5px;
				}
			}
			.image {
				margin: 5px 0 5px 10px;
			}
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
	}
	.header-top > .container .holiday_works {
		left: 443px;
	}
	.sphinxsearch {
		right: -1px;
	}
	.attr {
		.product-set-container {
			.product-set {
				.btn-group {
					.btn {
						padding: 10px;
					}
				}
			}
		}
	}
}

@media (max-width: $screen-md-max) and (min-width: $screen-md) {
	.pav-megamenu .navbar-nav > li > a {
		padding: 16px 13px;
		.menu-title {
			font-size: 14px;
		}
	}
}

@media (min-width: 992px) {
	.navbar-nav.megamenu {
		display: flex;
	}
}

/*$media (max-width: 991px) {*/
@media (max-width: $screen-sm-max) {
	.attr {
		.product-set-container {
			.product-set {
				.btn-group {
					.btn {
						font-size: 14px;
					}
				}
			}
		}
	}
	.row-offcanvas {
		overflow-y: visible;
	}
	.refine-search .catgrid .category-home {
		width: calc((100% / 3) - 20px);
	}
	.header-top > .container .holiday_works {
		left: 500px;
		top: 97px;
	}
	#content .product-info h1 {
		margin-top: 15px;
	}
	.widget-images {
		margin-bottom: 30px;
	}
	.home3 .layerslider-wrapper {
		margin-bottom: 30px;
	}
	.header-top .btn-group {
		margin-right: 10px;
	}
	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		padding-top: 15px;
		.panel {
			margin-bottom: 15px;
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.product-grid .product-block .action > div {
		padding-right: 2px;
	}
	.zoomContainer {
		display: none;
	}
	.custom-pull-right {
		display: none;
	}
	.payment-img {
		position: static;
	}
	#cart {
		padding: 0 15px;
		flex: 1 0 75px;
		.cart-inner {
			margin: 0;
		}
		.dropdown-menu {
			right: 15px;
		}
		.wrap-cart {
			position: relative;
			width: 55px;
			span {
				top: 7px;
			}
			.cart-total {
				text-transform: none;
				text-align: center;
			}
			.badge {
				position: absolute;
				top: -5px;
				right: -7px;
			}
		}
	}
	.logo-container {
		flex: 0 1 260px;
	}
	.offcanvas-body {
		padding: 0;
		.btn.offcanvas-close {
			background: transparent;
			position: absolute;
			right: 0;
			top: 4px;
			font-size: 18px;
		}
		.box.category {
			margin-bottom: 0;
		}
		.category .list-group li a {
			padding-right: 30px;
			padding-left: 10px;
		}
	}
	.offcanvas-heading {
		display: none;
	}
	.total-table .text-right {
		min-width: 80px;
	}
	#logo a {
		img {
			width: 100%;
		}
		.logo-text {
			font-size: 12px;
			left: 46px;
			white-space: nowrap;
		}
	}
	.sphinxsearch {
		width: 100%;
	}
	#powered {
		.payment-img {
			img {
				&:first-child {
					display: none;
				}
				&:last-child {
					display: block;
				}
			}
		}
	}
}

@media (max-width: $screen-sm) {
	.alert {
		margin: 10px 15px;
	}
	.quickview, .zoom {
		display: none !important;
	}
}

/*$media (max-width: 767px) {*/
@media (max-width: $screen-xs-max) {
	.attr {
		.product-set-container {
			h3 {
				.title-percent {
					&:first-child {
						right: 65px;
					}
					&:nth-child(2) {
						right: 24px;
					}
					&:nth-child(3) {
						right: 15px;
					}
					&:last-child {
						right:-47px
					}
				}
			}
			.product-set {
				flex-wrap: wrap;
				-ms-flex-wrap: wrap;
				.set-item {
					margin-bottom: 10px;
					.image {
						width: 40%;
						float: left;
						margin-right: 20px;
					}
					.set-sep {
						display: none;
					}
				}
				.btn-group {
					margin-top: 25px;
					border-top: 1px solid #ccc;
					padding-top: 25px;
					.prices {
						padding-left: 0;
						.new-price {
							padding-bottom: 26px;
						}
					}
					.btn {
						padding: 19px 37px;
						font-size: 18px;
					}
				}
			}
		}
	}
	.top-contact {
		.box-col-inner {
			padding-top: 9px;
			.media-body {
				font-size: 16px;
			}
		}
	}
	.header-top > .container .holiday_works {
		left: auto;
		right: 15px;
	}
	.information-opt .panel-contact-custom a {
		color: $bk !important;
	}
	#payment_methods {
		width: 100%;
	}
	.featured-category ul li {
		width: 100%;
	}
	#powered {
		height: auto;
		ul.links {
			margin-bottom: 40px;
		}
		.payment-img img {
			height: auto;
		}
	}
	.tab-v3 .nav-tabs > li > a {
		padding: 10px 5px;
		margin-bottom: 10px;
		font-size: 18px;
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}
	.compare-table tr td {
		max-width: 160px;
	}
	.checkout-cart table, .shopping-cart-table {
		background: $wh;
	}
	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}
	.product-filter .limit {
		display: none;
	}
	.success {
		width: 350px;
		margin-left: -175px;
		left: 50%;
		font-size: 10px;
		padding: 20px 10px 10px;
		a {
			font-size: 13px;
		}
		.success_button {
			padding: 6px 10px;
			font-size: 13px;
		}
		.close {
			top: 10px;
			right: 10px;
		}
	}
	.shopping-cart-table {
		& > thead {
			display: none;
		}
		&__product-image img {
			max-width: 160px;
		}
		td.image-col {
			width: 100%;
			text-align: center !important;
		}
		tbody, td, tr {
			display: block;
			border: 0;
			width: 100%;
		}
		td {
			position: relative;
			padding-left: 40% !important;
			text-align: left !important;
			white-space: normal !important;
			&:first-child {
				border-top: 0;
			}
			div.th-title {
				display: block;
				position: absolute;
				left: 10px;
				width: 38%;
				padding-right: 10px;
				text-align: right;
				top: 50%;
				transform: translateY(-50%);
				font-weight: bold;
				text-transform: uppercase;
			}
			&.no-title {
				padding-left: 20px !important;
				text-align: center !important;
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}
	.checkout-buttons {
		margin: 0;
		div {
			display: block;
			text-align: center;
			float: none !important;
			margin: 10px;
		}
	}
	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}
	.product-compare-header .badge, #cart .badge {
		position: absolute !important;
		top: -5px !important;
		right: -5px;
		padding: 5px;
		min-width: 23px;
		line-height: 11px;
	}
	.product-compare-header {
		position: absolute;
		top: 23px;
		right: 93px;
		width: 55px;
		border: none;
		border-radius: 3px;
		height: 47px;
		padding: 0;
		z-index: 9999;
		a {
			display: block;
			height: 100%;
		}
		i {
			font-size: 20px;
			position: relative;
			top: 14px;
		}
	}
	.header-top {
		.top-contact {
			flex: 1 1 80px;
		}
	}
	#cart {
		padding: 0;
		flex: 1 0 75px;
		z-index: 9999;
		width: auto;
		margin: 0 !important;
		right: 15px;
		top: 7px;
		a {
			display: block;
		}
		.wrap-cart {
			text-align: center;
			.cart-total {
				display: block;
				position: static;
			}
		}
		.icon-cart {
			margin-left: 0;
			float: none !important;
		}
	}
	.logo-store {
		text-align: left;
	}
	.refine-search {
		.panel-body {
			padding: 0;
		}
		.list-inline {
			margin: 0;
			a .name {
				font-size: 14px;
			}
		}
	}
	.product-grid {
		.products-block {
			.products-row {
				background: #f8fafc;
				.product-col {
					border-right: none;
				}
			}
			.product-block {
				.image {
					padding: 5px;
				}
				.product-meta .top {
					padding-top: 0;
				}
			}
		}
		.products-row, .products-block {
			border: none;
		}
	}
	.widget-images {
		margin-bottom: 0;
	}
	.homebuilder {
		.row-level-2 .panel-heading {
			padding: 0;
		}
		.producttabs {
			margin-bottom: 0;
		}
	}
	.catbanners {
		.row-level-2 .col-xs-6, .row-level-2 .col-xs-12 {
			padding: 1px;
		}
		.pav-inner {
			padding-top: 0;
		}
	}
	.fancybox-skin {
		padding: 5px !important;
	}
	.fancy_holiday_works.fancybox-opened {
		left: 10px !important;
		width: 295px !important;

		.fancybox-inner {
			width: 100% !important;
		}
	}
	.holiday_table {
		tr td {
			font-size: 14px;
			line-height: 22px;
		}
	}
	td.cart-thumb {
		padding: 0 !important;
		text-align: center !important;
	}
	.producttabs .nav li {
		display: block;
		float: none;
		text-align: center;
		font-size: 20px;
		&:before {
			display: none;
		}
		a {
			padding: 15px;
		}
	}
	#logo a .logo-text {
		font-size: 12px;
		left: 47px;
		white-space: nowrap;
	}
	.logo-container {
		flex: 0 0 200px;
	}
	.item.desc {
		table {
			width: auto !important;
			tr {
				td {
					&:first-child {
						width: 50% !important;
					}
				}
			}
			img {
				padding: 0 10px 10px 0;
			}
		}
	}
	.page-checkout-buy {
		#sidebar-main {
			#content {
				padding: 0;
			}
		}
	}
}

@media (max-width: 640px) {
	.product-allproduct, .product-category, .col-md-12 {
		.product-grid .product-block {
			width: calc((100% / 2) - 2px);
		}
	}
	.refine-search .catgrid .category-home {
		width: calc((100% / 2) - 20px);
	}
}

@media (max-width: $screen-xs) {
	.product-filter .filter-right > div {
		margin-bottom: 5px;
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
}

@media (max-width: 600px) {
	.box-col .media-body {
		overflow: visible;
	}
	.holiday_works {
		white-space: nowrap;
	}
}

@media (max-width: 500px) {
	#cart-total-mob {
		display: none !important;
	}
	.product-compare-header {
		top: 60px;
		width: 45px;
		height: 37px;
		right: 83px;
		i {
			top: 9px;
		}
	}
	#cart {
		top: 44px;
		height: 37px;
		width: 55px;
		flex: 1 0 55px;
		.icon-cart {
			padding: 4px;
			width: 45px;
			height: 37px;
		}
	}
	.top-contact {
		flex: none;
		position: absolute;
		width: 100%;
		right: 0;
		top: 15px;
		.box-col-inner {
			padding-top: 0;
		}
		.media-body {
			text-align: right;
			padding-right: 15px;
			span:last-child {
				display: none;
			}
		}
	}
}

@media (max-width: 468px) {
	.dropdown-menu {
		right: 0;
		min-width: 288px;
	}
	.box-col {
		padding: 0 4px;
		.media-body {
			font-size: 10px;
		}
	}
	h1 {
		font-size: 30px;
	}
	h2 {
		font-size: 26px;
	}
	#logo a .logo-text {
		font-size: 12px;
		line-height: 11px;
		top: 7px;
		position: relative;
		left: 0;
	}
	.logo-container {
		flex: 1 1 135px;
	}
	.sphinxsearch {
		width: calc(100vw - 30px);
	}
}

@media (max-width: 450px) {
	#finded-low-cost-form h3 {
		font-size: 18px;
		text-align: center;
		margin-top: 0;
	}

	.product-allproduct, .product-category, .col-md-12 {
		.product-grid .product-block {
			width: calc(100% - 2px);
		}
	}
}

@media (max-width: 380px) {
	.refine-search .catgrid .category-home {
		font-size: 12px;
	}
}

@media (max-width: 360px) {
	.product-markdown-view {
		.cart {
			.btn {
				width: 100%;
			}
		}
		.wrapper-btn-credt {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}
		.credit {
			button {
				width: 100%;
			}
		}
	}
}
@media (max-width: 350px) {
	.attr {
		.product-set-container {
			.product-set {
				.set-item {
					.price, .name {
						padding-left: 125px;
						height: max-content !important;
					}
				}
			}
		}
	}
	.success {
		width: 300px;
		margin-left: -150px;
		left: 50%;
		font-size: 13px;
		padding: 20px 10px 10px;
		a {
			font-size: 13px
		}
		.success_button {
			padding: 6px 10px
		}
		.close {
			top: 10px;
			right: 10px
		}
	}
	#cart .dropdown-menu {
		min-width: 320px;
		.table-cart .btn {
			font-size: 12px;
		}
	}
}